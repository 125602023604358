import { Typography } from '@material-ui/core';
import React, { useState } from 'react';
import styled from 'styled-components';
import PersonalDataDialog from '../dialogs/personal-data-dialog/PersonalDataDialog';
const OuterContainer = styled.div`
    position: relative;
    margin-bottom: 2rem;
`;
const UnderlinedText = styled.span`
    text-decoration: underline;
    cursor: pointer;
`;
const TermsOfPurchaseContainer = styled.div`
    height: 25rem;

    overflow: scroll;
    overflow-x: hidden;

    & :last-child {
        padding-bottom: 3rem;
    }
`;

const GradientOverlay = styled.div`
    height: 6rem;
    width: 100%;
    background-image: linear-gradient(rgba(21, 19, 19, 0), rgba(21, 19, 19, 1));
    position: absolute;
    bottom: 0;
`;
//
const Text = styled(Typography)`
    padding: 0.3rem 0rem;
    width: 75%;
    font-size: 1rem;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 90%;
        font-size: 0.8rem;
    }
`;
const TextTitle = styled(Typography)`
    font-size: 1.2rem;
    padding: 0.8rem 0rem 0.1rem 0rem;
    color: ${(props) => props.theme.palette.colors.orange};
    width: 80%;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 90%;
        font-size: 1rem;
    }
`;

const SubTitle = styled(Typography)`
    font-size: 1.2rem;
    padding: 0.3rem 0rem 0.1rem;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        font-size: 1rem;
    }
`;
const LinkText = styled.a`
    text-decoration: underline;
`;
const OrderedList = styled.ol`
    @media (max-width: 350px) {
        padding: 13px;
    }
`;

const UnorderedList = styled.ul`
    @media (max-width: 350px) {
        padding: 13px;
    }
`;
const ListItem = styled.li`
    width: 70%;
    font-size: 1rem;
    padding: 0.5rem 0rem;
    margin-bottom: 0rem !important;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        font-size: 0.8rem;
    }
    @media (max-width: 350px) {
        width: 100%;
    }
`;
function TermsOfPurchase({ isDialog }) {
    const [isPersonalDataDialogOpen, setIsPersonalDataDialogOpen] = useState(
        false
    );
    const showPersonalDataRules = () => {
        setIsPersonalDataDialogOpen(true);
    };
    return (
        <OuterContainer>
            {isDialog ? (
                <>
                    <TermsOfPurchaseContainer>
                        <SubTitle gutterBottom>
                            Dette websted{' '}
                            <LinkText
                                target="_blank"
                                rel="noopener noreferrer"
                                href="bulowsvej10.dk"
                            >
                                {' '}
                                bulowsvej10.dk
                            </LinkText>{' '}
                            ejes og drives af:
                        </SubTitle>
                        <Text gutterBottom>
                            Den Ny Musikskole ApS <br /> Bülowsvej 10
                            <br /> 1870 Frederiksberg
                            <br /> CVR.nr.: 33382898
                            <br /> Telefon: 30 14 01 23 <br />
                            <LinkText href="mailto:kontakt@bulowsvej10.dk">
                                kontakt@bulowsvej10.dk
                            </LinkText>
                        </Text>
                        <TextTitle variant="body2" gutterBottom>
                            Medlemskab og lokaleleje
                        </TextTitle>

                        <Text gutterBottom>
                            På{' '}
                            <LinkText
                                target="_blank"
                                rel="noopener noreferrer"
                                href="bulowsvej10.dk"
                            >
                                bulowsvej10.dk
                            </LinkText>{' '}
                            kan du oprette et medlemskab, som giver adgang til
                            alle ledige lokaler, der er angivet på
                            lokaleoversigten, 24/7 i hele medlemsperioden.
                        </Text>

                        <Text variant="body2" gutterBottom>
                            Du kan også reservere lokaler i en selvvalgt
                            tidsperiode. Lokalereservation kan kombineres med
                            medlemskab, men det er ikke et krav.
                        </Text>

                        <Text variant="body2" gutterBottom>
                            I nedenstående tekst vil begreberne medlem, lejer og
                            bruger kunne optræde synonymt. Det samme gælder
                            begreberne reservation, leje og booking.
                        </Text>

                        <Text variant="body2" gutterBottom>
                            Medlemskabet betales månedsvis forud og løber indtil
                            det opsiges. Medlemskabet kan opsiges løbende til
                            udgangen af igangværende betalingsperiode
                        </Text>

                        <Text variant="body2" gutterBottom>
                            Opsigelse skal ske via min side. Opsigelsen skal
                            være foretaget senest 24 timer før en ny
                            medlemsperiode påbegyndes
                        </Text>

                        <TextTitle variant="body2" gutterBottom>
                            Betaling og misligholdelse
                        </TextTitle>

                        <Text variant="body2" gutterBottom>
                            Betaling foregår automatisk via vores
                            betalingsudbydere. Du får en e-mail, når betalingen
                            er gennemført. Du skal være fyldt 18 år for at
                            handle hos os. Er du under 18 år, skal du have
                            samtykke fra en forælder eller værge.
                        </Text>

                        <Text variant="body2" gutterBottom>
                            En aftale kan altid annulleres af en forælder eller
                            værge, hvis det skulle vise sig, at den er blevet
                            indgået af et barn eller en person under 18 år.
                        </Text>

                        <Text variant="body2" gutterBottom>
                            Købsaftalen indgås på dansk. Den er juridisk
                            bindende, når du har modtaget ordrebekræftelsen.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Medlemskab og leje er personligt og må ikke
                            overdrages eller udlånes til andre – hverken helt
                            eller delvist.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Medlemmet/lejeren skal altid selv være fysisk til
                            stede, når udlejningslokaler og fællesarealer tages
                            i brug.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Et brud på dette, er en kraftig misligholdelse af
                            aftalen, og vil medføre øjeblikkelig bortvisning af
                            medlemmet/lejeren, uden økonomisk kompensation for
                            allerede betalt medlemskab/leje. Det samme gælder
                            ved udøvelse af hærværk på lokaler, inventar eller
                            bygningen i øvrigt.
                        </Text>

                        <TextTitle variant="body2" gutterBottom>
                            Parkering
                        </TextTitle>
                        <Text variant="body2" gutterBottom>
                            Biler kan parkeres i gården mod betaling via
                            parkeringsapp (område 118).
                            <br />
                            Der er automatisk kameraovervågning, som registrerer
                            nummerplade ved ind- og udkørsel. Regler og vilkår
                            fremgår af skiltningen på pladsen.
                            <br />
                            Udlejeren har intet ansvar for eventuelle bøder ved
                            manglende betaling for parkeringen.
                        </Text>

                        <Text variant="body2" gutterBottom>
                            Medlemmer og lejere forpligter sig til at parkere
                            cykler foran huset ud mod Bülowsvej, samt at sikre
                            at eventuelle elever, deltagere, kunder, klienter og
                            andre gæster gør det samme.
                        </Text>

                        <TextTitle variant="body2" gutterBottom>
                            Adgang
                        </TextTitle>
                        <Text variant="body2" gutterBottom>
                            Ved indgåelse af medlemsaftale og/eller lejeaftale
                            tildeles der adgang til huset via brugerens
                            mobiltelefon.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Det er ikke udlejerens ansvar, hvis
                            medlemmet/lejeren ikke kan få adgang pga. manglende
                            mobiltelefon.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Adgang til huset sker via bagdøren.
                            <br />
                            Døren står permanent låst og kan kun åbnes via ”lås
                            op” knappen, når du er logget ind på hjemmesiden og
                            er fysisk tæt på døren.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Døren har dørpumpe og lukker af sig selv. Det er
                            ikke tilladt at holde døren åben med nogen
                            genstande. Døren må kun åbnes og holdes fysisk åben
                            af personer.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            ’Lås op’ -knappen virker kun når du har et gyldigt
                            medlemskab eller en lejeaftale.
                        </Text>

                        <Text variant="body2" gutterBottom>
                            For lejere, der ikke er medlemmer, er der adgang til
                            bygningen og fællesarealerne 15 minutter før en
                            påbegyndt lejeperiode.
                        </Text>

                        <TextTitle variant="body2" gutterBottom>
                            Skofri bygning
                        </TextTitle>
                        <Text variant="body2" gutterBottom>
                            Det meste af huset er skofrit område. Skoene sættes
                            i skoreolerne i lokalerne omkring indgangsdøren.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Alternativt tages der et særligt overtræk ud over
                            skoene. Hvis du har gæster med, er det dit ansvar,
                            at de også tager skoene af eller overtræk på.
                        </Text>

                        <TextTitle variant="body2" gutterBottom>
                            Lokalernes anvendelse
                        </TextTitle>
                        <Text variant="body2" gutterBottom>
                            Lokalerne kan f.eks. anvendes til undervisning,
                            akustisk øvning, sang, kor, møder, massage, terapi,
                            kontor, fester, fødselsdage, foredrag, yoga, Tai Chi
                            m.m.
                            <br />
                            Spørg os, hvis du er i tvivl om, hvorvidt lokalerne
                            kan benyttes til din aktivitet.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Brugeren er ansvarlig for, at de benyttede lokaler
                            omgås forsvarligt. Brugeren er erstatningsansvarlig
                            for enhver skade – herunder også hændelige skader –
                            som påføres de benyttede lokaler, inventaret eller
                            ejendommen i øvrigt.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Ændringer i de benyttede lokaler må kun foretages
                            med ejers skriftlige godkendelse. Har brugeren efter
                            aftale foretaget ændringer af de benyttede lokaler,
                            er brugeren ved medlemskabets eller lejens ophør
                            forpligtet til at reetablere lokalerne, med mindre
                            ejer skriftligt frafalder dette krav.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Lokalerne efterlades generelt i samme tilstand og
                            opstilling, som ved brugerens ankomst og
                            ibrugtagning: Møbler og andet inventar stilles på
                            deres plads, klaverlåg lukkes ned, vinduer lukkes og
                            affald tages med ud af rummet.
                        </Text>

                        <TextTitle variant="body2" gutterBottom>
                            Forbrugsregnskab
                        </TextTitle>
                        <Text variant="body2" gutterBottom>
                            Den Lille Musikskole sørger for levering af el, vand
                            og varme.
                        </Text>

                        <TextTitle variant="body2" gutterBottom>
                            Husorden og brug af udenoms- og fællesarealer
                        </TextTitle>
                        <Text variant="body2" gutterBottom>
                            Som bruger af huset Bülowsvej 10, skal følgende
                            husorden overholdes:
                        </Text>

                        <OrderedList>
                            <ListItem>
                                Alle, der færdes i huset, skal tage deres sko af
                                i garderoben eller bruge overtræk. Den, der er
                                medlem eller lejer, har det fulde ansvar for, at
                                også deres elever/kunder/gæster overholder dette
                            </ListItem>
                            <ListItem>
                                De farvede taburetter må kun benyttes af børn
                            </ListItem>
                            <ListItem>
                                Støjende aktiviteter skal altid udøves for
                                lukkede vinduer og døre
                            </ListItem>
                            <ListItem>
                                Der skal luftes ud inden lokalet forlades
                            </ListItem>
                            <ListItem>
                                Klaverlåg lukkes ned og alle møbler, samt
                                inventar stilles pænt på plads.
                            </ListItem>
                            <ListItem>
                                Affald bæres ned i køkkenet til vores
                                affaldssortering
                            </ListItem>
                            <ListItem>Hele matriklen holdes røgfri</ListItem>
                            <ListItem>
                                Udendørs ophold og aktiviteter må ikke være til
                                gene for naboerne
                            </ListItem>
                            <ListItem>
                                Brug af andre instrumenter end klaver og guitar
                                kræver særskilt aftale med Den Lille Musikskole,
                                som er ejer af samtlige instrumenter på stedet
                            </ListItem>
                            <ListItem>
                                Instrumenter, inventar m.m. må ikke fjernes fra
                                huset uden forudgående aftale med Den Lille
                                Musikskole
                            </ListItem>
                        </OrderedList>

                        <TextTitle variant="body2" gutterBottom>
                            Inventar
                        </TextTitle>
                        <Text variant="body2" gutterBottom>
                            Den Lille Musikskole, som er fast bruger af
                            lokalerne hos Bülowsvej 10, har en del rekvisitter
                            liggende, som ikke er til brug af andre end
                            musikskolen.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Følgende inventar er til fri afbenyttelse:
                        </Text>
                        <UnorderedList>
                            <ListItem>
                                Klaver/flygel (låget lukkes efter brug)
                            </ListItem>
                            <ListItem>
                                Faldunderlagsmåtter (pakkes sammen igen efter
                                brug)
                            </ListItem>
                            <ListItem>
                                Meditationspuder (lægges på plads efter brug)
                            </ListItem>
                            <ListItem>
                                Massagebriks (tørres af og foldes sammen efter
                                brug)
                            </ListItem>
                            <ListItem>
                                Tæpper (foldes og lægges på plads efter brug)
                            </ListItem>
                            <ListItem>
                                Borde og stole (sættes på plads efter brug)
                            </ListItem>
                            <ListItem>
                                Farvede taburetter (NB! Må kun benyttes af børn)
                            </ListItem>
                            <ListItem>
                                Køkken og service (beskidt service sættes i
                                opvaskemaskinen. NB! Hvis opvaskemaskinen
                                indeholder rent service, sættes dette på plads
                                først)
                            </ListItem>
                        </UnorderedList>

                        <Text variant="body2" gutterBottom>
                            NB! Brug af andre instrumenter, inventar og
                            rekvisitter end nævnt ovenfor, kræver særskilt
                            forudgående skriftlig aftale med Den Lille
                            Musikskole.
                        </Text>

                        <TextTitle variant="body2" gutterBottom>
                            Kopimaskine
                        </TextTitle>
                        <Text variant="body2" gutterBottom>
                            Det er muligt at benytte vores printer/kopimaskine
                            mod betaling.
                            <br />
                            Sort/hvid: 25 øre per kopi
                            <br />
                            Farve 50: øre per kop
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Efter brug af printer/kopimaskine.
                            <br />
                            Send en mail med antal kopier og type til:
                            kontakt@bulowsvej10.dk
                            <br />
                            Vi har tillid til, at vores brugere er ansvarlige og
                            ærlige.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Vi sender en faktura for kopier:
                        </Text>
                        <ul>
                            <li>Når antallet af kopier runder 1000 stk.</li>{' '}
                            <li>Ved ophør af medlemskab</li>
                            <li>Ved ophør af lejeperiode </li>
                        </ul>

                        <TextTitle variant="body2" gutterBottom>
                            Betaling
                        </TextTitle>

                        <Text variant="body2" gutterBottom>
                            Vi accepterer betaling fra Dankort, Visa/Dankort,
                            MasterCard. Vi opkræver ikke kreditkortgebyrer. Alle
                            priser er angivet i DKK.
                        </Text>

                        <Text variant="body2" gutterBottom>
                            Der reserveres den fulde pris for din lokaleleje på
                            dit kort. Hvis du er medlem, eller opretter
                            medlemskab, vil du kunne se den fulde pris, som
                            reserveres på dit kort, samt den aktuelle pris
                            indeholdende medlemsrabat. Dit kort vil kun blive
                            debiteret for den pris, der er tilsvarende
                            medlemsprisen, så længe du er medlem.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Efter at købet er gennemført, sendes du tilbage til
                            hjemmesiden bulowsvej10.dk, hvor du får en
                            opsummering på dit køb.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Du modtager en e-mail, når betalingen er gennemført
                            (OBS der kan gå op til 24 timer).
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Hvis du ikke har modtaget en e-mail fra os efter 24
                            timer, skal du tjekke dit spamfilter eller kontakte
                            os på e-mail: kontakt@bulowsvej10.dk.
                        </Text>

                        <TextTitle variant="body2" gutterBottom>
                            Opsigelse og fortrydelsesret
                        </TextTitle>
                        <Text variant="body2" gutterBottom>
                            Der er ingen fortrydelsesret på køb af medlemskab
                            eller lokaleleje.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Du kan opsige medlemskabet til udgangen af din
                            medlemsperiode, som løber en måned ad gangen.
                        </Text>

                        <Text variant="body2" gutterBottom>
                            For at gøre dette, skal du gå ind på min side, og
                            klikke på 'Opsig medlemskab'. Du vil kunne bruge
                            huset efter gældende aftale, indtil kl. 23.59 den
                            dag dit medlemskab udløber.
                        </Text>
                        {/* <Text variant="body2" gutterBottom>
                            Reservation af lokaler kan annulleres efter følgende
                            vilkår:
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Alle reservationer kan til hver en tid annulleres.{' '}
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Ved annullering betales fra 0 til 50% af
                            reservationsprisen:{' '}
                        </Text>

                        <UnorderedList>
                            <ListItem>
                                Medlemmer, der annullerer mere end en uge før
                                reservationstidspunktet, betaler 0% når
                                reservationen annulleres{' '}
                            </ListItem>
                            <ListItem>
                                Medlemmer, der annullerer mindre end en uge før
                                reservationstidspunktet, betaler 25% når
                                reservationen annulleres{' '}
                            </ListItem>
                            <ListItem>
                                Ikke-medlemmer, der annullerer mere end en uge
                                før reservationstidspunktet, betaler 25% når
                                reservationen annulleres{' '}
                            </ListItem>
                            <ListItem>
                                Ikke-medlemmer, der annullerer mindre end en uge
                                før reservationstidspunktet, betaler 50% når
                                reservationen annulleres
                            </ListItem>
                        </UnorderedList> */}

                        <TextTitle variant="body2" gutterBottom>
                            Opslagstavlen
                        </TextTitle>
                        <Text variant="body2" gutterBottom>
                            Opslagstavlen er en gratis og valgfri ekstraservice
                            brugerne af bulowsvej10.dk. Her kan man oprette et
                            opslag hvor man udbyder sin undervisning o.lign.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Sådan gør du:
                        </Text>

                        <ul>
                            <li>
                                Giv dit opslag en kort og præcis titel (eks.
                                Guitarundervisning)
                            </li>
                            <li>
                                Beskriv din undervisning / dit tilbud så præcist
                                og beskrivende som muligt. Der er plads til 700
                                tegn.
                            </li>
                            <li>
                                Tilføj kontaktoplysninger og eventuelt links til
                                sociale medier. Du vælger selv, hvordan du vil
                                kontaktes, din mail og telefonnummer er dog
                                skjult, så det kræver ekstra handling fra
                                besøgende, at få det synligt.
                            </li>
                            <li>
                                Upload op til 5 billeder af dig selv, dit
                                instrument, sessions etc.
                            </li>
                            <li>
                                Vælg en eller flere kategorier for dit opslag.
                            </li>
                            <li>
                                · Dit opslag er aktivt i 30 dage fra dit sidste
                                login, eller indtil du deaktiverer det. 3 dage
                                før dit opslag udløber, vil du modtage en e-mail
                                med en påmindelse og et genaktiveringslink. Hvis
                                ikke du trykker på linket inden for 3 dage,
                                bliver opslaget automatisk deaktiveret. Du kan
                                til hver en tid genaktivere og redigere dit
                                opslag.
                            </li>
                        </ul>

                        <Text variant="body2" gutterBottom>
                            NB! Den Lille Musikskole har intet ansvar for den
                            undervisning, som brugerne af opslagstavlen
                            tilbyder.
                        </Text>

                        <TextTitle variant="body2" gutterBottom>
                            Klageadgang
                        </TextTitle>
                        <Text variant="body2" gutterBottom>
                            Hvis du som forbruger vil klage over dit køb, skal
                            du kontakte os på e-mail:{' '}
                            <LinkText href="mailto:kontakt@bulowsvej10.dk">
                                kontakt@bulowsvej10.dk
                            </LinkText>
                            . Hvis det ikke lykkes os at finde en løsning, kan
                            du sende en klage til:
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Center for Klageløsning
                            <br />
                            Nævnenes Hus
                            <br />
                            Toldboden 2<br />
                            8800 Viborg
                            <br />
                            www.naevneneshus.dk
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Ved indgivelse af en klage, skal du oplyse denne
                            e-mailadresse:{' '}
                            <LinkText href="mailto:kontakt@bulowsvej10.dk">
                                kontakt@bulowsvej10.dk
                            </LinkText>
                            .
                        </Text>

                        <TextTitle variant="body2" gutterBottom>
                            Lov og værneting
                        </TextTitle>
                        <Text variant="body2" gutterBottom>
                            Køb på{' '}
                            <LinkText
                                target="_blank"
                                rel="noopener noreferrer"
                                href="bulowsvej10.dk"
                            >
                                {' '}
                                bulowsvej10.dk
                            </LinkText>{' '}
                            er omfattet af dansk ret, og værneting er
                            forbrugerens hjemting. Personer under 18 år kan ikke
                            handle på{' '}
                            <LinkText
                                target="_blank"
                                rel="noopener noreferrer"
                                href="bulowsvej10.dk"
                            >
                                {' '}
                                bulowsvej10.dk
                            </LinkText>
                            , uden samtykke fra en værge.
                        </Text>

                        <TextTitle variant="body2" gutterBottom>
                            Ansvarsfraskrivelse
                        </TextTitle>
                        <Text variant="body2" gutterBottom>
                            Den Ny Musikskole ApS/bulowsvej10.dk kan til enhver
                            tid ændre indholdet af denne side. Den Ny Musikskole
                            ApS/bulowsvej10.dk kan ikke drages til ansvar for
                            eventuelle skader opstået som resultat af brug af
                            siden eller for den sags skyld manglende adgang til
                            brug af siden.
                        </Text>

                        <TextTitle variant="body2" gutterBottom>
                            Persondatapolitik
                        </TextTitle>
                        <Text variant="body2" gutterBottom>
                            Vi har brug for følgende oplysninger, når du
                            opretter en profil og/eller handler hos os: Navn,
                            adresse, e-mailadresse og telefonnummer.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Vi registrerer de personoplysninger, som er
                            nødvendige for, at du kan oprette et medlemskab
                            og/eller reservere lokaler. Personoplysningerne
                            registreres hos Den Ny Musikskole/bulowsvej10.dk og
                            opbevares i fem år, hvorefter oplysningerne slettes.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Vi behandler dine personoplysninger fortroligt, og
                            du kan altid logge ind og se dine oplysninger eller
                            lukke din konto, så alle data anonymiseres, i det
                            omfang lovgivningen tillader det.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Vi er jævnfør regnskabsloven forpligtet til at gemme
                            regnskabsinformationer om betalinger i fem år.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Vi samarbejder med de virksomheder, der er
                            nødvendige for at drifte betalingsmodul og
                            hjemmeside. Virksomhederne behandler udelukkende
                            oplysninger på vores vegne og må ikke bruge dem til
                            egne formål.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Vi samarbejder kun med databehandlere i EU eller i
                            lande, der kan give dine oplysninger en
                            tilstrækkelig beskyttelse.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Den dataansvarlige på{' '}
                            <LinkText
                                target="_blank"
                                rel="noopener noreferrer"
                                href="bulowsvej10.dk"
                            >
                                {' '}
                                bulowsvej10.dk
                            </LinkText>{' '}
                            er Den Ny Musikskole ApS.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Du har ret til at få oplyst, hvilke oplysninger vi
                            har registreret om dig. Hvis du mener, at
                            oplysningerne er unøjagtige, har du ret til at få
                            dem rettet. I nogle tilfælde har vi pligt til at
                            slette dine persondata, hvis du beder om det. Det
                            kan f.eks. være, hvis dine data ikke længere er
                            nødvendige i forhold til det formål, som vi skulle
                            bruge dem til. Du kan også kontakte os, hvis du
                            mener, at dine persondata bliver behandlet i strid
                            med lovgivningen. Du kan skrive til os på:{' '}
                            <LinkText href="mailto:kontakt@bulowsvej10.dk">
                                kontakt@bulowsvej10.dk
                            </LinkText>
                            .
                        </Text>
                        {/* come back */}
                        <Text variant="body2" gutterBottom>
                            For yderligere oplysninger om vores håndtering af
                            persondata, se da under vores{' '}
                            <UnderlinedText onClick={showPersonalDataRules}>
                                persondatapolitik
                            </UnderlinedText>
                            .
                        </Text>

                        <TextTitle variant="body2" gutterBottom>
                            Ansvar og risici
                        </TextTitle>
                        <Text variant="body2" gutterBottom>
                            Den Ny Musikskole ApS bærer ikke risici eller
                            forsikrer mod tyveri af brugers personlige
                            genstande. Medlemmer og lejere bør derfor have
                            instrumentforsikring og forsikring imod personligt
                            tyveri.
                        </Text>

                        <TextTitle variant="body2" gutterBottom>
                            Force Majeure
                        </TextTitle>
                        <Text variant="body2" gutterBottom>
                            Såfremt Den Ny Musikskole ApS i en periode er
                            nødsaget til at lukke adgang til huset, som følge af
                            force majeure og force majeure lignende
                            begivenheder, gives ikke refunderinger, erstatning,
                            kompensation, forholdsmæssigt afslag i prisen eller
                            lignende, som følge af sådanne begivenheder. Som
                            force majeure og force majeure lignende begivenheder
                            anses eksempelvis naturkatastrofer, brand, strejker,
                            pandemier o.l.
                        </Text>

                        <TextTitle>Kontakt</TextTitle>
                        <Text variant="body2" gutterBottom>
                            Har du spørgsmål, eller er du i tvivl om noget i
                            forbindelse med din oprettelse af medlemskab eller
                            reservation af lokaler, så kan du kontakte os på
                            telefon 30 14 01 23 (mandag og onsdag kl. 10-12).
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Du kan også skrive til os på{' '}
                            <LinkText href="mailto:kontakt@bulowsvej10.dk">
                                kontakt@bulowsvej10.dk
                            </LinkText>
                            , så svarer vi inden for 1-3 hverdage.
                        </Text>
                        <small>
                            Handels- og medlemsbetingelser er senest opdateret
                            den 17. november 2023
                        </small>
                    </TermsOfPurchaseContainer>
                    <GradientOverlay />
                </>
            ) : (
                <>
                    <SubTitle gutterBottom>
                        Dette websted{' '}
                        <LinkText
                            target="_blank"
                            rel="noopener noreferrer"
                            href="bulowsvej10.dk"
                        >
                            {' '}
                            bulowsvej10.dk
                        </LinkText>{' '}
                        ejes og drives af:
                    </SubTitle>
                    <Text gutterBottom>
                        Den Ny Musikskole ApS <br /> Bülowsvej 10
                        <br /> 1870 Frederiksberg
                        <br /> CVR.nr.: 33382898
                        <br /> Telefon: 30 14 01 23 <br />
                        <LinkText href="mailto:kontakt@bulowsvej10.dk">
                            kontakt@bulowsvej10.dk
                        </LinkText>
                    </Text>
                    <TextTitle variant="body2" gutterBottom>
                        Medlemskab og lokaleleje
                    </TextTitle>

                    <Text gutterBottom>
                        På{' '}
                        <LinkText
                            target="_blank"
                            rel="noopener noreferrer"
                            href="bulowsvej10.dk"
                        >
                            bulowsvej10.dk
                        </LinkText>{' '}
                        kan du oprette et medlemskab, som giver adgang til alle
                        ledige lokaler, der er angivet på lokaleoversigten, 24/7
                        i hele medlemsperioden.
                    </Text>

                    <Text variant="body2" gutterBottom>
                        Du kan også reservere lokaler i en selvvalgt
                        tidsperiode. Lokalereservation kan kombineres med
                        medlemskab, men det er ikke et krav.
                    </Text>

                    <Text variant="body2" gutterBottom>
                        I nedenstående tekst vil begreberne medlem, lejer og
                        bruger kunne optræde synonymt. Det samme gælder
                        begreberne reservation, leje og booking.
                    </Text>

                    <Text variant="body2" gutterBottom>
                        Medlemskabet betales månedsvis forud og løber indtil det
                        opsiges. Medlemskabet kan opsiges løbende til udgangen
                        af igangværende betalingsperiode
                    </Text>

                    <Text variant="body2" gutterBottom>
                        Opsigelse skal ske via min side. Opsigelsen skal være
                        foretaget senest 24 timer før en ny medlemsperiode
                        påbegyndes
                    </Text>

                    <TextTitle variant="body2" gutterBottom>
                        Betaling og misligholdelse
                    </TextTitle>

                    <Text variant="body2" gutterBottom>
                        Betaling foregår automatisk via vores betalingsudbydere.
                        Du får en e-mail, når betalingen er gennemført. Du skal
                        være fyldt 18 år for at handle hos os. Er du under 18
                        år, skal du have samtykke fra en forælder eller værge.
                    </Text>

                    <Text variant="body2" gutterBottom>
                        En aftale kan altid annulleres af en forælder eller
                        værge, hvis det skulle vise sig, at den er blevet
                        indgået af et barn eller en person under 18 år.
                    </Text>

                    <Text variant="body2" gutterBottom>
                        Købsaftalen indgås på dansk. Den er juridisk bindende,
                        når du har modtaget ordrebekræftelsen.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Medlemskab og leje er personligt og må ikke overdrages
                        eller udlånes til andre – hverken helt eller delvist.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Medlemmet/lejeren skal altid selv være fysisk til stede,
                        når udlejningslokaler og fællesarealer tages i brug.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Et brud på dette, er en kraftig misligholdelse af
                        aftalen, og vil medføre øjeblikkelig bortvisning af
                        medlemmet/lejeren, uden økonomisk kompensation for
                        allerede betalt medlemskab/leje. Det samme gælder ved
                        udøvelse af hærværk på lokaler, inventar eller bygningen
                        i øvrigt.
                    </Text>

                    <TextTitle variant="body2" gutterBottom>
                        Parkering
                    </TextTitle>
                    <Text variant="body2" gutterBottom>
                        Biler kan parkeres i gården mod betaling via
                        parkeringsapp (område 118).
                        <br />
                        Der er automatisk kameraovervågning, som registrerer
                        nummerplade ved ind- og udkørsel. Regler og vilkår
                        fremgår af skiltningen på pladsen.
                        <br />
                        Udlejeren har intet ansvar for eventuelle bøder ved
                        manglende betaling for parkeringen.
                    </Text>

                    <Text variant="body2" gutterBottom>
                        Medlemmer og lejere forpligter sig til at parkere cykler
                        foran huset ud mod Bülowsvej, samt at sikre at
                        eventuelle elever, deltagere, kunder, klienter og andre
                        gæster gør det samme.
                    </Text>

                    <TextTitle variant="body2" gutterBottom>
                        Adgang
                    </TextTitle>
                    <Text variant="body2" gutterBottom>
                        Ved indgåelse af medlemsaftale og/eller lejeaftale
                        tildeles der adgang til huset via brugerens
                        mobiltelefon.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Det er ikke udlejerens ansvar, hvis medlemmet/lejeren
                        ikke kan få adgang pga. manglende mobiltelefon.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Adgang til huset sker via bagdøren.
                        <br />
                        Døren står permanent låst og kan kun åbnes via ”lås op”
                        knappen, når du er logget ind på hjemmesiden og er
                        fysisk tæt på døren.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Døren har dørpumpe og lukker af sig selv. Det er ikke
                        tilladt at holde døren åben med nogen genstande. Døren
                        må kun åbnes og holdes fysisk åben af personer.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        ’Lås op’ -knappen virker kun når du har et gyldigt
                        medlemskab eller en lejeaftale.
                    </Text>

                    <Text variant="body2" gutterBottom>
                        For lejere, der ikke er medlemmer, er der adgang til
                        bygningen og fællesarealerne 15 minutter før en
                        påbegyndt lejeperiode.
                    </Text>

                    <TextTitle variant="body2" gutterBottom>
                        Skofri bygning
                    </TextTitle>
                    <Text variant="body2" gutterBottom>
                        Det meste af huset er skofrit område. Skoene sættes i
                        skoreolerne i lokalerne omkring indgangsdøren.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Alternativt tages der et særligt overtræk ud over
                        skoene. Hvis du har gæster med, er det dit ansvar, at de
                        også tager skoene af eller overtræk på.
                    </Text>

                    <TextTitle variant="body2" gutterBottom>
                        Lokalernes anvendelse
                    </TextTitle>
                    <Text variant="body2" gutterBottom>
                        Lokalerne kan f.eks. anvendes til undervisning, akustisk
                        øvning, sang, kor, møder, massage, terapi, kontor,
                        fester, fødselsdage, foredrag, yoga, Tai Chi m.m.
                        <br />
                        Spørg os, hvis du er i tvivl om, hvorvidt lokalerne kan
                        benyttes til din aktivitet.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Brugeren er ansvarlig for, at de benyttede lokaler omgås
                        forsvarligt. Brugeren er erstatningsansvarlig for enhver
                        skade – herunder også hændelige skader – som påføres de
                        benyttede lokaler, inventaret eller ejendommen i øvrigt.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Ændringer i de benyttede lokaler må kun foretages med
                        ejers skriftlige godkendelse. Har brugeren efter aftale
                        foretaget ændringer af de benyttede lokaler, er brugeren
                        ved medlemskabets eller lejens ophør forpligtet til at
                        reetablere lokalerne, med mindre ejer skriftligt
                        frafalder dette krav.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Lokalerne efterlades generelt i samme tilstand og
                        opstilling, som ved brugerens ankomst og ibrugtagning:
                        Møbler og andet inventar stilles på deres plads,
                        klaverlåg lukkes ned, vinduer lukkes og affald tages med
                        ud af rummet.
                    </Text>

                    <TextTitle variant="body2" gutterBottom>
                        Forbrugsregnskab
                    </TextTitle>
                    <Text variant="body2" gutterBottom>
                        Den Lille Musikskole sørger for levering af el, vand og
                        varme.
                    </Text>

                    <TextTitle variant="body2" gutterBottom>
                        Husorden og brug af udenoms- og fællesarealer
                    </TextTitle>
                    <Text variant="body2" gutterBottom>
                        Som bruger af huset Bülowsvej 10, skal følgende husorden
                        overholdes:
                    </Text>

                    <OrderedList>
                        <ListItem>
                            Alle, der færdes i huset, skal tage deres sko af i
                            garderoben eller bruge overtræk. Den, der er medlem
                            eller lejer, har det fulde ansvar for, at også deres
                            elever/kunder/gæster overholder dette
                        </ListItem>
                        <ListItem>
                            De farvede taburetter må kun benyttes af børn
                        </ListItem>
                        <ListItem>
                            Støjende aktiviteter skal altid udøves for lukkede
                            vinduer og døre
                        </ListItem>
                        <ListItem>
                            Der skal luftes ud inden lokalet forlades
                        </ListItem>
                        <ListItem>
                            Klaverlåg lukkes ned og alle møbler, samt inventar
                            stilles pænt på plads.
                        </ListItem>
                        <ListItem>
                            Affald bæres ned i køkkenet til vores
                            affaldssortering
                        </ListItem>
                        <ListItem>Hele matriklen holdes røgfri</ListItem>
                        <ListItem>
                            Udendørs ophold og aktiviteter må ikke være til gene
                            for naboerne
                        </ListItem>
                        <ListItem>
                            Brug af andre instrumenter end klaver og guitar
                            kræver særskilt aftale med Den Lille Musikskole, som
                            er ejer af samtlige instrumenter på stedet
                        </ListItem>
                        <ListItem>
                            Instrumenter, inventar m.m. må ikke fjernes fra
                            huset uden forudgående aftale med Den Lille
                            Musikskole
                        </ListItem>
                    </OrderedList>

                    <TextTitle variant="body2" gutterBottom>
                        Inventar
                    </TextTitle>
                    <Text variant="body2" gutterBottom>
                        Den Lille Musikskole, som er fast bruger af lokalerne
                        hos Bülowsvej 10, har en del rekvisitter liggende, som
                        ikke er til brug af andre end musikskolen.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Følgende inventar er til fri afbenyttelse:
                    </Text>
                    <UnorderedList>
                        <ListItem>
                            Klaver/flygel (låget lukkes efter brug)
                        </ListItem>
                        <ListItem>
                            Faldunderlagsmåtter (pakkes sammen igen efter brug)
                        </ListItem>
                        <ListItem>
                            Meditationspuder (lægges på plads efter brug)
                        </ListItem>
                        <ListItem>
                            Massagebriks (tørres af og foldes sammen efter brug)
                        </ListItem>
                        <ListItem>
                            Tæpper (foldes og lægges på plads efter brug)
                        </ListItem>
                        <ListItem>
                            Borde og stole (sættes på plads efter brug)
                        </ListItem>
                        <ListItem>
                            Farvede taburetter (NB! Må kun benyttes af børn)
                        </ListItem>
                        <ListItem>
                            Køkken og service (beskidt service sættes i
                            opvaskemaskinen. NB! Hvis opvaskemaskinen indeholder
                            rent service, sættes dette på plads først)
                        </ListItem>
                    </UnorderedList>

                    <Text variant="body2" gutterBottom>
                        NB! Brug af andre instrumenter, inventar og rekvisitter
                        end nævnt ovenfor, kræver særskilt forudgående skriftlig
                        aftale med Den Lille Musikskole.
                    </Text>

                    <TextTitle variant="body2" gutterBottom>
                        Kopimaskine
                    </TextTitle>
                    <TextTitle variant="body2" gutterBottom>
                        Det er muligt at benytte vores printer/kopimaskine mod
                        betaling.
                        <br />
                        Sort/hvid: 25 øre per kopi
                        <br />
                        Farve 50: øre per kop
                    </TextTitle>
                    <TextTitle variant="body2" gutterBottom>
                        Efter brug af printer/kopimaskine.
                        <br />
                        Send en mail med antal kopier og type til:
                        kontakt@bulowsvej10.dk
                        <br />
                        Vi har tillid til, at vores brugere er ansvarlige og
                        ærlige.
                    </TextTitle>
                    <TextTitle variant="body2" gutterBottom>
                        Vi sender en faktura for kopier:
                    </TextTitle>
                    <ul>
                        <li>Når antallet af kopier runder 1000 stk.</li>{' '}
                        <li>Ved ophør af medlemskab</li>
                        <li>Ved ophør af lejeperiode </li>
                    </ul>

                    <TextTitle variant="body2" gutterBottom>
                        Betaling
                    </TextTitle>

                    <Text variant="body2" gutterBottom>
                        Vi accepterer betaling fra Dankort, Visa/Dankort,
                        MasterCard. Vi opkræver ikke kreditkortgebyrer. Alle
                        priser er angivet i DKK.
                    </Text>

                    <Text variant="body2" gutterBottom>
                        Der reserveres den fulde pris for din lokaleleje på dit
                        kort. Hvis du er medlem, eller opretter medlemskab, vil
                        du kunne se den fulde pris, som reserveres på dit kort,
                        samt den aktuelle pris indeholdende medlemsrabat. Dit
                        kort vil kun blive debiteret for den pris, der er
                        tilsvarende medlemsprisen, så længe du er medlem.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Efter at købet er gennemført, sendes du tilbage til
                        hjemmesiden bulowsvej10.dk, hvor du får en opsummering
                        på dit køb.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Du modtager en e-mail, når betalingen er gennemført (OBS
                        der kan gå op til 24 timer).
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Hvis du ikke har modtaget en e-mail fra os efter 24
                        timer, skal du tjekke dit spamfilter eller kontakte os
                        på e-mail: kontakt@bulowsvej10.dk.
                    </Text>

                    <TextTitle variant="body2" gutterBottom>
                        Opsigelse og fortrydelsesret
                    </TextTitle>
                    <Text variant="body2" gutterBottom>
                        Der er ingen fortrydelsesret på køb af medlemskab eller
                        lokaleleje.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Du kan opsige medlemskabet til udgangen af din
                        medlemsperiode, som løber en måned ad gangen.
                    </Text>

                    <Text variant="body2" gutterBottom>
                        For at gøre dette, skal du gå ind på min side, og klikke
                        på 'Opsig medlemskab'. Du vil kunne bruge huset efter
                        gældende aftale, indtil kl. 23.59 den dag dit medlemskab
                        udløber.
                    </Text>
                    {/* <Text variant="body2" gutterBottom>
                            Reservation af lokaler kan annulleres efter følgende
                            vilkår:
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Alle reservationer kan til hver en tid annulleres.{' '}
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Ved annullering betales fra 0 til 50% af
                            reservationsprisen:{' '}
                        </Text>

                        <UnorderedList>
                            <ListItem>
                                Medlemmer, der annullerer mere end en uge før
                                reservationstidspunktet, betaler 0% når
                                reservationen annulleres{' '}
                            </ListItem>
                            <ListItem>
                                Medlemmer, der annullerer mindre end en uge før
                                reservationstidspunktet, betaler 25% når
                                reservationen annulleres{' '}
                            </ListItem>
                            <ListItem>
                                Ikke-medlemmer, der annullerer mere end en uge
                                før reservationstidspunktet, betaler 25% når
                                reservationen annulleres{' '}
                            </ListItem>
                            <ListItem>
                                Ikke-medlemmer, der annullerer mindre end en uge
                                før reservationstidspunktet, betaler 50% når
                                reservationen annulleres
                            </ListItem>
                        </UnorderedList> */}

                    <TextTitle variant="body2" gutterBottom>
                        Opslagstavlen
                    </TextTitle>
                    <Text variant="body2" gutterBottom>
                        Opslagstavlen er en gratis og valgfri ekstraservice
                        brugerne af bulowsvej10.dk. Her kan man oprette et
                        opslag hvor man udbyder sin undervisning o.lign.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Sådan gør du:
                    </Text>

                    <ul>
                        <li>
                            Giv dit opslag en kort og præcis titel (eks.
                            Guitarundervisning)
                        </li>
                        <li>
                            Beskriv din undervisning / dit tilbud så præcist og
                            beskrivende som muligt. Der er plads til 700 tegn.
                        </li>
                        <li>
                            Tilføj kontaktoplysninger og eventuelt links til
                            sociale medier. Du vælger selv, hvordan du vil
                            kontaktes, din mail og telefonnummer er dog skjult,
                            så det kræver ekstra handling fra besøgende, at få
                            det synligt.
                        </li>
                        <li>
                            Upload op til 5 billeder af dig selv, dit
                            instrument, sessions etc.
                        </li>
                        <li>Vælg en eller flere kategorier for dit opslag.</li>
                        <li>
                            · Dit opslag er aktivt i 30 dage fra dit sidste
                            login, eller indtil du deaktiverer det. 3 dage før
                            dit opslag udløber, vil du modtage en e-mail med en
                            påmindelse og et genaktiveringslink. Hvis ikke du
                            trykker på linket inden for 3 dage, bliver opslaget
                            automatisk deaktiveret. Du kan til hver en tid
                            genaktivere og redigere dit opslag.
                        </li>
                    </ul>

                    <Text variant="body2" gutterBottom>
                        NB! Den Lille Musikskole har intet ansvar for den
                        undervisning, som brugerne af opslagstavlen tilbyder.
                    </Text>

                    <TextTitle variant="body2" gutterBottom>
                        Klageadgang
                    </TextTitle>
                    <Text variant="body2" gutterBottom>
                        Hvis du som forbruger vil klage over dit køb, skal du
                        kontakte os på e-mail:{' '}
                        <LinkText href="mailto:kontakt@bulowsvej10.dk">
                            kontakt@bulowsvej10.dk
                        </LinkText>
                        . Hvis det ikke lykkes os at finde en løsning, kan du
                        sende en klage til:
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Center for Klageløsning
                        <br />
                        Nævnenes Hus
                        <br />
                        Toldboden 2<br />
                        8800 Viborg
                        <br />
                        www.naevneneshus.dk
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Ved indgivelse af en klage, skal du oplyse denne
                        e-mailadresse:{' '}
                        <LinkText href="mailto:kontakt@bulowsvej10.dk">
                            kontakt@bulowsvej10.dk
                        </LinkText>
                        .
                    </Text>

                    <TextTitle variant="body2" gutterBottom>
                        Lov og værneting
                    </TextTitle>
                    <Text variant="body2" gutterBottom>
                        Køb på{' '}
                        <LinkText
                            target="_blank"
                            rel="noopener noreferrer"
                            href="bulowsvej10.dk"
                        >
                            {' '}
                            bulowsvej10.dk
                        </LinkText>{' '}
                        er omfattet af dansk ret, og værneting er forbrugerens
                        hjemting. Personer under 18 år kan ikke handle på{' '}
                        <LinkText
                            target="_blank"
                            rel="noopener noreferrer"
                            href="bulowsvej10.dk"
                        >
                            {' '}
                            bulowsvej10.dk
                        </LinkText>
                        , uden samtykke fra en værge.
                    </Text>

                    <TextTitle variant="body2" gutterBottom>
                        Ansvarsfraskrivelse
                    </TextTitle>
                    <Text variant="body2" gutterBottom>
                        Den Ny Musikskole ApS/bulowsvej10.dk kan til enhver tid
                        ændre indholdet af denne side. Den Ny Musikskole
                        ApS/bulowsvej10.dk kan ikke drages til ansvar for
                        eventuelle skader opstået som resultat af brug af siden
                        eller for den sags skyld manglende adgang til brug af
                        siden.
                    </Text>

                    <TextTitle variant="body2" gutterBottom>
                        Persondatapolitik
                    </TextTitle>
                    <Text variant="body2" gutterBottom>
                        Vi har brug for følgende oplysninger, når du opretter en
                        profil og/eller handler hos os: Navn, adresse,
                        e-mailadresse og telefonnummer.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Vi registrerer de personoplysninger, som er nødvendige
                        for, at du kan oprette et medlemskab og/eller reservere
                        lokaler. Personoplysningerne registreres hos Den Ny
                        Musikskole/bulowsvej10.dk og opbevares i fem år,
                        hvorefter oplysningerne slettes.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Vi behandler dine personoplysninger fortroligt, og du
                        kan altid logge ind og se dine oplysninger eller lukke
                        din konto, så alle data anonymiseres, i det omfang
                        lovgivningen tillader det.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Vi er jævnfør regnskabsloven forpligtet til at gemme
                        regnskabsinformationer om betalinger i fem år.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Vi samarbejder med de virksomheder, der er nødvendige
                        for at drifte betalingsmodul og hjemmeside.
                        Virksomhederne behandler udelukkende oplysninger på
                        vores vegne og må ikke bruge dem til egne formål.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Vi samarbejder kun med databehandlere i EU eller i
                        lande, der kan give dine oplysninger en tilstrækkelig
                        beskyttelse.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Den dataansvarlige på{' '}
                        <LinkText
                            target="_blank"
                            rel="noopener noreferrer"
                            href="bulowsvej10.dk"
                        >
                            {' '}
                            bulowsvej10.dk
                        </LinkText>{' '}
                        er Den Ny Musikskole ApS.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Du har ret til at få oplyst, hvilke oplysninger vi har
                        registreret om dig. Hvis du mener, at oplysningerne er
                        unøjagtige, har du ret til at få dem rettet. I nogle
                        tilfælde har vi pligt til at slette dine persondata,
                        hvis du beder om det. Det kan f.eks. være, hvis dine
                        data ikke længere er nødvendige i forhold til det
                        formål, som vi skulle bruge dem til. Du kan også
                        kontakte os, hvis du mener, at dine persondata bliver
                        behandlet i strid med lovgivningen. Du kan skrive til os
                        på:{' '}
                        <LinkText href="mailto:kontakt@bulowsvej10.dk">
                            kontakt@bulowsvej10.dk
                        </LinkText>
                        .
                    </Text>
                    {/* come back */}
                    <Text variant="body2" gutterBottom>
                        For yderligere oplysninger om vores håndtering af
                        persondata, se da under vores{' '}
                        <UnderlinedText onClick={showPersonalDataRules}>
                            persondatapolitik
                        </UnderlinedText>
                        .
                    </Text>

                    <TextTitle variant="body2" gutterBottom>
                        Ansvar og risici
                    </TextTitle>
                    <Text variant="body2" gutterBottom>
                        Den Ny Musikskole ApS bærer ikke risici eller forsikrer
                        mod tyveri af brugers personlige genstande. Medlemmer og
                        lejere bør derfor have instrumentforsikring og
                        forsikring imod personligt tyveri.
                    </Text>

                    <TextTitle variant="body2" gutterBottom>
                        Force Majeure
                    </TextTitle>
                    <Text variant="body2" gutterBottom>
                        Såfremt Den Ny Musikskole ApS i en periode er nødsaget
                        til at lukke adgang til huset, som følge af force
                        majeure og force majeure lignende begivenheder, gives
                        ikke refunderinger, erstatning, kompensation,
                        forholdsmæssigt afslag i prisen eller lignende, som
                        følge af sådanne begivenheder. Som force majeure og
                        force majeure lignende begivenheder anses eksempelvis
                        naturkatastrofer, brand, strejker, pandemier o.l.
                    </Text>

                    <TextTitle>Kontakt</TextTitle>
                    <Text variant="body2" gutterBottom>
                        Har du spørgsmål, eller er du i tvivl om noget i
                        forbindelse med din oprettelse af medlemskab eller
                        reservation af lokaler, så kan du kontakte os på telefon
                        30 14 01 23 (mandag og onsdag kl. 10-12).
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Du kan også skrive til os på{' '}
                        <LinkText href="mailto:kontakt@bulowsvej10.dk">
                            kontakt@bulowsvej10.dk
                        </LinkText>
                        , så svarer vi inden for 1-3 hverdage.
                    </Text>
                    <small>
                        Handels- og medlemsbetingelser er senest opdateret den
                        17. november 2023
                    </small>
                </>
            )}
            {isPersonalDataDialogOpen && (
                <PersonalDataDialog
                    close={() => setIsPersonalDataDialogOpen(false)}
                />
            )}
        </OuterContainer>
    );
}

export default TermsOfPurchase;
