import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import debounce from 'lodash.debounce';
import 'moment/locale/da';

import Day from './day/Day';
import RoomSelect from './room-select/RoomSelect';
import MyCartIcon from '../../assets/icons/basket.png';

import '../../index.css';

// Helper functions
import {
    buildCalendar,
    getEndDate,
    getEndDateMobile,
    getWeekNumber,
    nextWeek,
    prevWeek
} from './CalendarFunctions';
import { fetchAndFilterEvents, sortEvents } from './EventFunctions';

// MUI components
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
// eslint-disable-next-line
import CircularProgress from '@material-ui/core/CircularProgress';
// eslint-disable-next-line
import BookingSummary from './booking-summary/BookingSummary';
// eslint-disable-next-line
import {
    deleteAndReturnResp,
    getAndReturnData,
    postAndReturnResp
} from '../../context/helperFunctions';
import {
    getEventIdFromStorage,
    getProperRoomId,
    getOwnEvents,
    getUserId,
    returnRepeatSeqId,
    getDateFromQuery,
    returnAuthenticated,
    hasPrevPageDestination,
    getPrevPageDestination,
    getPrevPageUrl
} from '../../context/getData';
import { Context } from '../../context/Context';
import ReturnLinkReusable from '../links/ReturnLinkReusable';
import ErrorDialog from '../dialogs/error-dialog/ErrorDialog';
import DeleteDialog from '../dialogs/delete-dialog/DeleteDialog';
import { withRouter } from 'react-router-dom';
import SuccessBookingDialog from '../dialogs/success-booking-dialog/SuccessBookingDialog';
import ConflictingEventsDialog from '../dialogs/conflicting-events-dialog/ConflictingEventsDialog';
import RegistrationContentContainer from '../containers/registration/RegistrationContentContainer';
import { overridings } from '../../themes/DarkTheme';

// Check4: If its a mobile device, only display the short names of the days.
// Ask: are we sure we dont need month on the mobile design next to the day??
// Fix bug with exapnded thing. Maybe the whole available timeslot div should have pointer.
// Error handling: what if no data is coming from server?
// Create http requests
// Make the events same width on mobile device, or make the date div same width

const CalendarContainer = styled.div``;

// const UpwardArrow = styled(Typography)`
//   text-align: center;
// `;

const CalendarHeadlineContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
`;

const MiddleColumn = styled.div`
    position: relative;
    width: 100%;
`;

const MainContainer = styled.div`
    display: block;
    width: 100%;
    margin: 1rem auto;
    @media (min-width: 960px) {
        display: none;
    }
`;

const CalendarTitle = styled(Typography)`
    margin-top: 0.5rem;
    font-size: 1.2rem;
    text-align: left;
`;
// eslint-disable-next-line
const BackLink = styled(Typography)`
    display: none;
`;
// eslint-disable-next-line
const SmallChevron = styled.span`
    font-size: 1.2rem;
    margin: 0.5rem;
    font-family: 'Benton Sans Medium';
    cursor: pointer;
`;

const CartButton = styled(Box)`
    background: ${overridings.palette.colors.green};
    padding: 15px 18px;
    border-radius: 50%;
    position: fixed;
    bottom: 30px;
    right: 30px;
`;

const CartImg = styled.img``;

const MonthContainer = styled.div`
    width: 100vw;
    background-color: ${(props) => props.theme.palette.secondary.light};
    position: relative;
    padding: 1rem 2rem;
    margin: 2rem 0;
`;

const MonthName = styled(Typography)`
    font-family: 'Benton Sans Medium';
`;

class MobileCalendar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dateObject: moment(),
            dates: [],
            weekNumber: '',
            today: '',
            chosenRoom: '1',
            events: [],
            monthName: '',
            isLoading: false,
            roomId: 1,
            startDate: '',
            endDate: '',
            isBookingCalendar: false,

            ownEvents: [],
            ownEventIds: [],

            // Error dialog
            error: false,
            errorMessage: '',

            // Success dialog
            showSuccessBooking: false,
            // Events, which has been created after submiting a request -  These are displayed in the success dialog
            justBookedEvents: [],

            //Conflict booking dialog
            showConflictBooking: false,
            // Events, which has been created after submiting a request -  These are displayed in the conflict events dialog
            conflictingEvents: [],
            conflictPostRequestBody: {},

            //Dialog for confirming the deletion of booking
            deleteDialog: false,
            deleteDialogMessage: '',
            selectedEventIdToDelete: null,
            multipleEvents: false,

            // Editing a selected event
            selectedEventIdToEdit: null
            // isUpwardArrowClick:false
        };

        // Check is user scrolled to bottom of the page in case of using mobile version
        window.onscroll = debounce(() => {
            const {
                state: { error, isLoading }
            } = this;
            // Bails early if:
            // * there's an error
            // * it's already loading
            if (error || isLoading) return;
            // Checks that the page has scrolled to the bottom
            if (
                parseInt(
                    window.innerHeight + document.documentElement.scrollTop
                ) === document.documentElement.offsetHeight ||
                parseInt(
                    window.innerHeight + document.documentElement.scrollTop + 1
                ) === document.documentElement.offsetHeight ||
                parseInt(
                    window.innerHeight + document.documentElement.scrollTop - 1
                ) === document.documentElement.offsetHeight
            ) {
                this.setDateObjectMobile(nextWeek(this.state.dateObject));
                fetchAndFilterEvents(
                    this.state.isBookingCalendar,
                    this.state.chosenRoom,
                    this.state.startDate,
                    this.state.endDate,
                    this.handleError,
                    this
                );
            }
        }, 100);
    }

    static contextType = Context;
    colors = ['blue', 'purple', 'green', 'orange', 'yellow'];

    // When user scrolls to bottom in mobile version a new week is loaded
    setDateObjectMobile = (week, isPrevWeek) => {
        this.setState(
            {
                dateObject: week
            },
            () => {
                const datesArray = [
                    ...this.state.dates,
                    ...buildCalendar(this.state.dateObject, true)
                ];
                this.setState({
                    dates: datesArray,
                    endDate: getEndDate(this.state.dateObject)
                });
            }
        );

        // If upward arrow is clicked, change url query param
        if (isPrevWeek) {
            let path;
            if (window.location.href.includes('booking-kalender')) {
                path = 'booking-kalender';
            } else {
                path = 'aktivitets-kalender';
            }
            this.props.history.push(
                `/${path}/${this.state.chosenRoom}?date=${moment(week).format(
                    'YYYY-MM-DD'
                )}`
            );
            window.location.reload();
        }
    };

    // Come back: handle error
    handleError = (message) => {
        let errorMessage = 'Noget gik galt. Prøv igen.';
        if (message) {
            errorMessage = message;
        }
        this.setState({ error: true, errorMessage });
    };

    setJustBookedEvents = (events) => {
        this.setState({ justBookedEvents: events, showSuccessBooking: true });
    };

    setConflictingEvents = (events) => {
        this.setState({ conflictingEvents: events, showConflictBooking: true });
    };

    // Get selected room and change the events rendered in calendar according to the it
    getSelectedRoom = (room) => {
        this.setState({ chosenRoom: Number(room) }, () => {
            fetchAndFilterEvents(
                this.state.isBookingCalendar,
                this.state.chosenRoom,
                this.state.startDate,
                this.state.endDate,
                this.handleError,
                this
            );
            this.setState({ roomId: getProperRoomId(this.state.chosenRoom) });
        });
    };

    // filterData = (dataFromApi, isBookingCalendar, ownEvents) => {
    //     //Should filter incoming data
    //     const filteredData = dataFromApi.filter(
    //         (event) => event.roomId === getProperRoomId(this.state.chosenRoom)
    //     );

    //     const events = createEvents(filteredData, isBookingCalendar, ownEvents);
    //     this.setState({ events });
    // };

    // fetchAndFilterEvents = async (isBookingCalendar) => {
    //     const properRoomId = getProperRoomId(this.state.chosenRoom);
    //     const data = await fetchEvents(
    //         properRoomId,
    //         this.state.startDate,
    //         this.state.endDate,
    //         this.handleError
    //     );
    //     const ownEvents = await getOwnEvents(() => {
    //         console.log('error when getting own events');
    //     });
    //     if (isBookingCalendar) {
    //         this.filterData(data, isBookingCalendar, ownEvents);
    //     } else {
    //         this.filterData(data, this.state.isBookingCalendar, ownEvents);
    //     }
    // };

    // User created a new event, we want to store it in an array, in order to display it below the calendar
    addNewOwnEvent = (event) => {
        // const eventsFromStorage = getEvents() ? getEvents() : [];
        // // Add new event
        // eventsFromStorage.push(event);
        // this.context.saveEvents(eventsFromStorage);
        // this.setState({ ownEvents: eventsFromStorage }, () => {});
        // // If there is an event id to edit saved to storage, remove that
        // if (getEventIdFromStorage() && getEventIdFromStorage() !== 0) {
        //   this.context.saveEventIdToEdit(0);
        // }
    };

    // Updates the list of own events
    // updateOwnEventsList = (updatedEventsArray) => {
    //   this.setState({ ownEvents: updatedEventsArray });
    // };

    getOwnEventObjects = (eventIds, events) => {
        const ownEventObjects = [];
        for (let i = 0; i < eventIds.length; i++) {
            events.forEach((event) => {
                if (event.event_id === eventIds[i]) {
                    ownEventObjects.push(event);
                }
            });
        }
        return ownEventObjects;
    };

    fetchAndFilterEventsInCalendar = () => {
        fetchAndFilterEvents(
            this.state.isBookingCalendar,
            this.state.chosenRoom,
            this.state.startDate,
            this.state.endDate,
            this.handleError,
            this
        );
    };

    // A dialog is displayed to make the user confirm the deletion
    // If they press yes, the removeBookedEvent will be run
    confirmRemovingEvent = async (eventId, repeatSeqId) => {
        if (repeatSeqId) {
            // Check if there are multiple events to delete
            // If there are, we need to ask the user,  if they want to delete all related events
            const data = await getAndReturnData(
                `/api/v1/events/${repeatSeqId}`,
                this.handleError
            );
            if (data && data.events && data.events.length > 1) {
                this.setState({
                    deleteDialog: true,
                    selectedEventIdToDelete: eventId,
                    multipleEvents: true
                });
            } else {
                this.setState({
                    deleteDialog: true,
                    selectedEventIdToDelete: eventId
                });
            }
        } else {
            this.setState({
                deleteDialog: true,
                selectedEventIdToDelete: eventId
            });
        }
    };

    // User is able to delete its own event by confirming the deletion in the DeleteDialog
    removeBookedEvent = async (eventId, repeatSeqId) => {
        const getUrl = () => {
            if (repeatSeqId !== undefined) {
                return `/api/v1/users/${getUserId()}/events/draft/${eventId}?repeatSeqId=${repeatSeqId}`;
            } else {
                return `/api/v1/users/${getUserId()}/events/draft/${eventId}`;
            }
        };
        const resp = await deleteAndReturnResp(getUrl(), this.handleError);
        if (resp && resp.status === 200) {
            this.updateOwnEvents();
            this.setState({ deleteDialog: false });
        } else {
            this.handleError(
                'Noget gik galt. Kunne ikke fjerne reservationen.  Prøv igen.'
            );
            this.setState({ deleteDialog: false });
        }

        // Update events by getting events data from backend, and update own events.
        fetchAndFilterEvents(
            this.state.isBookingCalendar,
            this.state.chosenRoom,
            this.state.startDate,
            this.state.endDate,
            this.handleError,
            this
        );
        this.updateOwnEvents(this, this.handleError);
    };

    setMultipleEvents = (multipleEvents) => {
        this.setState({ multipleEvents });
    };

    editBookedEvent = async (eventId) => {
        this.setState({ selectedEventIdToEdit: eventId });
    };

    removeEdit = () => {
        this.setState({ selectedEventIdToEdit: null });
        if (getEventIdFromStorage()) {
            this.context.saveEventIdToEdit(0);
        }
    };
    // Before fix
    // updateOwnEvents = async () => {
    //   const ownEvents = await getOwnEvents(this.handleError);
    //   if (ownEvents) {
    //     this.setState({ ownEvents: sortEvents(ownEvents) });
    //   }
    // };

    updateOwnEvents = async () => {
        if (returnAuthenticated()) {
            const ownEvents = await getOwnEvents(this.handleError);
            if (ownEvents) {
                const onlyDrafts = ownEvents.filter(
                    (event) => event.state === 'draft'
                );
                if (onlyDrafts) {
                    this.setState({ ownDraftEvents: sortEvents(onlyDrafts) });
                }
            }
        }
    };

    componentDidMount() {
        // Get room id from path parameters
        if (
            this.props &&
            this.props.match &&
            this.props.match.params &&
            this.props.match.params.id
        ) {
            this.setState(
                {
                    chosenRoom: Number(this.props.match.params.id)
                },
                () => {
                    this.setState({
                        roomId: getProperRoomId(this.state.chosenRoom)
                    });
                }
            );
        }

        //Find out if it is activity calendar or booking calendar, because we display events accordingly
        if (window.location.href.includes('booking-kalender')) {
            this.setState({ isBookingCalendar: true });
        } else {
            this.setState({ isBookingCalendar: false });
        }

        // Get dateobject from query Url. That shows, what week we should show
        const dateFromQuery = getDateFromQuery(this);
        let dateObject;
        if (dateFromQuery) {
            dateObject = dateFromQuery;
        }
        // else {
        //   dateObject = this.state.dateObject;
        // }
        // If mobile device, render data and days from today.
        //Build calendar and  get start and end date
        this.setState(
            {
                dates: buildCalendar(dateObject, true),
                weekNumber: getWeekNumber(dateObject),
                startDate: dateObject,
                endDate: getEndDateMobile(dateObject),
                dateObject: dateObject
            },
            () => {
                fetchAndFilterEvents(
                    this.state.isBookingCalendar,
                    this.state.chosenRoom,
                    this.state.startDate,
                    this.state.endDate,
                    this.handleError,
                    this
                );
            }
        );

        this.updateOwnEvents(this, this.handleError);

        // Check if there is any event id to edit in local storage
        if (getEventIdFromStorage() && getEventIdFromStorage() !== 0) {
            this.setState({ selectedEventIdToEdit: getEventIdFromStorage() });
        }
    }

    // Sending the request body, which is needed to create a recurring booking, if there is conflicts,
    // and the user wants to create the events anyways.
    setConflictPostRequestBody = (requestBody) => {
        this.setState({ conflictPostRequestBody: requestBody });
    };

    handleUpwardArrowClick = () => {
        const dateFromQuery = getDateFromQuery(this);
        this.setDateObjectMobile(prevWeek(moment(dateFromQuery)), true);
        //  this.setState({isUpwardArrowClick :true})
    };

    handleCartButtonClick = () => {
        this.props.history.push('/opsummering');
    };

    bookNonConflictingEvents = async () => {
        // See comments about this part in DesktopCalendar.jsx
        const response = await postAndReturnResp(
            '/api/v1/events/non-conflicting-events/booking',
            this.state.conflictPostRequestBody,
            this.handleError
        );

        if (!response || response.status !== 200) {
            return this.handleError();
        }

        if (response && response.status === 200) {
            const data = await response.json();
            if (
                data &&
                data.createdEvents &&
                data.createdEvents.length > 0 &&
                data.createdEvents[0].id
            ) {
                fetchAndFilterEvents(
                    this.state.isBookingCalendar,
                    this.state.chosenRoom,
                    this.state.startDate,
                    this.state.endDate,
                    this.handleError,
                    this
                );
                // this.addNewOwnEvent([...data.createdEvents]);
                this.setJustBookedEvents(data.createdEvents);
            }
        }
    };

    render() {
        return (
            <MainContainer>
                {/* <RegistrationContentContainer
          marginTop="2.5rem"
          mobilePaddingTop="1rem"
        > */}
                {/* {returnAuthenticated() ? (
            <ProgressBar currentStage={"Kalender"} type={getCurrentStage()} />
          ) : (
            <ProgressBar currentStage={"Kalender"} />
          )} */}

                {!returnAuthenticated() && (
                    <RegistrationContentContainer>
                        <Box mt="2rem">
                            <Typography variant="subtitle2">
                                Du skal være logget ind for at oprette en
                                reservation.
                            </Typography>
                        </Box>
                    </RegistrationContentContainer>
                )}

                <Box display="flex">
                    <MiddleColumn>
                        <CalendarHeadlineContainer>
                            <Box>
                                {hasPrevPageDestination(this.props) ? (
                                    <ReturnLinkReusable
                                        destination={getPrevPageDestination(
                                            this.props
                                        )}
                                        url={getPrevPageUrl(this.props)}
                                        marginBottom="0"
                                    />
                                ) : (
                                    <Box width="fit-content">
                                        <ReturnLinkReusable
                                            url="/"
                                            // destination="Hjemmesiden"
                                            marginBottom="0"
                                        />
                                    </Box>
                                )}

                                <CalendarTitle
                                    variant="h4"
                                    gutterBottom
                                    m="2"
                                    component="h1"
                                >
                                    Ugevisning
                                </CalendarTitle>
                            </Box>
                            <Box>
                                <RoomSelect
                                    getSelectedRoom={this.getSelectedRoom}
                                    chosenRoom={this.state.chosenRoom}
                                />
                            </Box>
                        </CalendarHeadlineContainer>
                        <CalendarContainer>
                            {/* <Box width="100%">
                <UpwardArrow onClick={() => this.handleUpwardArrowClick()}>
                  &#8679;
                </UpwardArrow>
              </Box> */}

                            {this.state.dates.map((day) => {
                                this.color = this.colors[
                                    Math.floor(
                                        Math.random() * this.colors.length
                                    )
                                ];
                                while (
                                    this.color === this.oldColor ||
                                    this.color === 'yellow'
                                ) {
                                    this.color = this.colors[
                                        Math.floor(
                                            Math.random() * this.colors.length
                                        )
                                    ];
                                }
                                this.oldColor = this.color;
                                return day.isMonthName ? (
                                    <MonthContainer key={day.key}>
                                        <MonthName key={day.key} variant="h6">
                                            {day.monthName}
                                        </MonthName>
                                    </MonthContainer>
                                ) : (
                                    <Day
                                        key={day.dateTimeValue}
                                        dayName={day.name}
                                        dayNameShort={day.nameShort}
                                        date={day.date}
                                        displayDate={day.displayDate}
                                        displayDatedShort={day.displayDateShort}
                                        isToday={day.isToday}
                                        events={this.state.events}
                                        roomId={this.state.roomId}
                                        fetchAndFilterEventsInCalendar={
                                            this.fetchAndFilterEventsInCalendar
                                        }
                                        addNewOwnEventId={this.addNewOwnEventId}
                                        // addNewOwnEvent={this.addNewOwnEvent}
                                        selectedEventIdToEdit={
                                            this.state.selectedEventIdToEdit
                                        }
                                        editBookedEvent={this.editBookedEvent}
                                        removeEdit={this.removeEdit}
                                        // updateOwnEventsList={this.updateOwnEventsList}
                                        handleError={this.handleError}
                                        ownEvents={this.state.ownDraftEvents}
                                        confirmRemovingEvent={
                                            this.confirmRemovingEvent
                                        }
                                        setJustBookedEvents={
                                            this.setJustBookedEvents
                                        }
                                        setConflictingEvents={
                                            this.setConflictingEvents
                                        }
                                        setConflictPostRequestBody={
                                            this.setConflictPostRequestBody
                                        }
                                        updateOwnEvents={this.updateOwnEvents}
                                        dayColor={this.color}
                                    />
                                );
                            })}
                        </CalendarContainer>
                    </MiddleColumn>
                </Box>
                {this.state.isLoading && (
                    <Box display="flex" justifyContent="center" pt="2rem">
                        <CircularProgress color="primary.contrastText" />
                    </Box>
                )}

                {this.state.error && (
                    <ErrorDialog
                        dialogTitle=""
                        close={() =>
                            this.setState({ error: false, errorMessage: '' })
                        }
                        errorMessage={this.state.errorMessage}
                    />
                )}

                {/* This seems to be a duplicate */}
                {/* {this.state.deleteDialog && (
                    <DeleteDialog
                        close={() =>
                            this.setState({
                                deleteDialog: false,
                                selectedEventIdToDelete: null
                            })
                        }
                        eventId={this.state.selectedEventIdToDelete}
                        removeBookedEvent={this.removeBookedEvent}
                        isDraftEvent={true}
                    />
                )} */}

                {this.state.showSuccessBooking && (
                    <SuccessBookingDialog
                        events={this.state.justBookedEvents}
                        close={() => {
                            this.setState({
                                showSuccessBooking: false,
                                justBookedEvents: [],
                                showConflictBooking: false
                            });
                        }}
                    />
                )}

                {this.state.showConflictBooking && (
                    <ConflictingEventsDialog
                        conflictingEvents={this.state.conflictingEvents}
                        bookNonConflictingEvents={this.bookNonConflictingEvents}
                        close={() => {
                            this.setState({
                                showConflictBooking: false,
                                conflictingEvents: []
                            });
                        }}
                    />
                )}

                {this.state.deleteDialog && (
                    <DeleteDialog
                        close={() =>
                            this.setState({
                                deleteDialog: false,
                                selectedEventIdToDelete: null
                            })
                        }
                        eventId={this.state.selectedEventIdToDelete}
                        repeatSeqId={returnRepeatSeqId(
                            this.state.ownEvents,
                            this.state.selectedEventIdToDelete
                        )}
                        removeBookedEvent={this.removeBookedEvent}
                        multipleEvents={this.state.multipleEvents}
                        isDraftEvent={true}
                        ownEvents={this.state.ownDraftEvents}
                    />
                )}

                {this.state.ownDraftEvents &&
                    this.state.ownDraftEvents.length > 0 && (
                        <CartButton
                            onClick={() => {
                                this.handleCartButtonClick();
                            }}
                        >
                            <CartImg alt="se kurv" src={MyCartIcon} />
                        </CartButton>
                    )}
            </MainContainer>
        );
    }
}

export default withRouter(MobileCalendar);
