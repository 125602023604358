import React from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import ClickableLink from '../components/buttons/clickable-link/ClickableLink';
import { returnAuthenticated } from '../context/getData';

const UnderlinedText = styled.span`
    text-decoration: underline;
    cursor: pointer;
`;
const Answer = styled(Typography)`
    margin-bottom: 1.2rem;
    width: 80%;

    &.qa-colourful-question {
        color: ${(props) => props.theme.palette.colors.orange};
    }

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 100%;
    }
`;

const MobileSpace = styled.span`
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        display: block;
    }
`;

const QAData = [
    {
        id: 1,
        question: 'Lokaler',
        answers: (
            <Answer variant="body2">
                Vi har 8 rum fra 8 til 42 kvadratmeter. Se dem{' '}
                <UnderlinedText>
                    <a href="/lokaler" target="_blank">
                        her
                    </a>
                </UnderlinedText>
                .
                <MobileSpace>
                    <br />
                </MobileSpace>
                Lokalerne kan blandt andet bruges til akustisk øvning og
                undervisning, møder, bevægelsesundervisning, massage og terapi.
            </Answer>
        )
    },
    {
        id: 2,
        question: 'Medlemskab',
        answers: (
            <>
                <Answer variant="body2">
                    Ubegrænset adgang til alle ledige lokaler for 400 kroner om
                    måneden.
                    <MobileSpace>
                        <br />
                    </MobileSpace>{' '}
                    Kan opsiges når som helst. Bliv medlem{' '}
                    <UnderlinedText>
                        {returnAuthenticated() ? (
                            <ClickableLink wantsMembership={true} text="her" />
                        ) : (
                            <ClickableLink
                                pathname="/oplysninger"
                                fromPath="/faq"
                                fromTitle="FAQ"
                                text="her"
                            />
                        )}
                    </UnderlinedText>
                    .
                </Answer>

                <Answer variant="subtitle1" className="qa-colourful-question">
                    Lej lokale uden medlemskab
                </Answer>

                <Answer variant="body2">
                    5 kroner i timen per kvadratmeter. Mindste tidsblok 15 min.
                    <MobileSpace>
                        <br />
                    </MobileSpace>
                    NB! 50% rabat på leje for medlemmer. Reserver lokale{' '}
                    <UnderlinedText>
                        <ClickableLink
                            pathname="/booking-kalender"
                            fromPath="/faq"
                            fromTitle="FAQ"
                            text="her"
                        />
                    </UnderlinedText>
                </Answer>

                <Answer variant="subtitle1" className="qa-colourful-question">
                    Oprettelse af medlemskab
                </Answer>
                <Answer variant="body2">
                    Du kan oprette dit medlemskab lige{' '}
                    <UnderlinedText>
                        {returnAuthenticated() ? (
                            <ClickableLink wantsMembership={true} text="her" />
                        ) : (
                            <ClickableLink
                                pathname="/oplysninger"
                                fromPath="/faq"
                                fromTitle="FAQ"
                                text="her"
                            />
                        )}
                    </UnderlinedText>
                    , det kan opsiges når som helst.
                </Answer>
            </>
        )
    },
    {
        id: 3,
        question: 'Husregler',
        answers: (
            <Answer variant="body2">
                Disse regler er hverken til forhandling eller gradbøjning, de
                ufravigelige
                <ul>
                    <li>
                        Det meste af huset er skofrit område. Overtræk eller
                        hjemmesko kan bruges, men aldrig udendørssko. Sko skal
                        placeres på skohylderne
                    </li>
                    <li>
                        Alle rum efterlades som beskrevet på listerne i det
                        enkelte lokale (husk at vende optaget/ledigt skiltet)
                    </li>
                    <li>Hele matriklen er røgfri zone</li>
                    <li>
                        Hele matriklen er skærmfri zone for børn under 18 år
                    </li>
                </ul>
            </Answer>
        )
    },
    {
        id: 4,
        question: 'Adgang til huset',
        answers: (
            <Answer variant="body2">
                Alle brugere får detaljer om adgangssystemet via mail efter
                reservation af lokale eller oprettelse af medlemskab.
                <MobileSpace>
                    <br />
                </MobileSpace>
                NB! Det meste af huset er skofrit område, der er skoreoler i de
                rum hvor der må bæres sko.
            </Answer>
        )
    },
    {
        id: 5,
        question: 'Parkering',
        answers: (
            <Answer variant="body2">
                Cykler stilles i stativerne foran huset.
                <MobileSpace>
                    <br />
                </MobileSpace>
                Biler kan parkeres i husets gård.
                <MobileSpace>
                    <br />
                </MobileSpace>
                Det koster 7 kr. i timen, der er ikke gratisperiode. <br />
                Nummerplader scannes ved indkørsel i gården.
                <MobileSpace>
                    <br />
                </MobileSpace>
                Læs skiltningen i gården, eller kontakt Parkeringskompagniet som
                administrerer parkeringen.
                <MobileSpace>
                    <br />
                </MobileSpace>
                Der kan også parkeres på gaderne i området omkring skolen.
            </Answer>
        )
    },
    {
        id: 6,
        question: 'Beliggenhed og transport',
        answers: (
            <Answer variant="body2">
                Huset ligger tæt ved Landbohøjskolen og Gl. Kongevej, med
                henholdsvis 450 og 800 meter til metrostationerne: Frederiksberg
                Allé (M3) og Frederiksberg (M1, M2, M3). Busser i nærheden: 9A,
                31, 26 og 71.
            </Answer>
        )
    },
    {
        id: 7,
        question: 'Gæster',
        answers: (
            <Answer variant="body2">
                Medlemmer og lejere kan medbringe gæster, elever, klienter og
                musikere.
                <MobileSpace>
                    <br />
                </MobileSpace>
                Det er brugerens (medlemmers/lejeres) ansvar at gæster
                overholder husreglerne.
            </Answer>
        )
    }
];

export default QAData;
