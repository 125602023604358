import { Box, Typography } from '@material-ui/core';
import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import {
    getLocationNameFromRoomId,
    returnTotalPrices,
    getUserId,
    getProperRoomId
} from '../../../context/getData';
import EventItem from '../../event-item/EventItem';
import RegistrationButton from '../../buttons/registration-button/RegistrationButton';
import { useContext } from 'react';
import { Context } from '../../../context/Context';
import { getAndReturnDataAndResponse } from './../../../context/helperFunctions';

const EventsContainer = styled(Box)``;
const SummaryComp = styled(Box)``;

const Event = styled(Box)``;

const LocationName = styled(Typography)``;

const UnderLine = styled.span`
    text-decoration: underline;
`;

function BookingSummary(props) {
    const [listOfRooms, setListOfRooms] = useState([]);
    const [normalTotalPrice, setNormalTotalPrice] = useState(0);
    const [discountTotalPrice, setDiscountTotalPrice] = useState(0);
    const [draftEvents, setDraftEvents] = useState([]);
    const [roomsBookedHoursList, setRoomsBookedHoursList] = useState([]);
    const context = useContext(Context);
    let bookedHoursList = [];
    const handleRegButtonClick = () => {
        // Take the user to Payment rates, if the bookings are bookings in multiple months
        // if (props.ownEvents && props.ownEvents.length > 0) {
        //   const uniqueListOfMonths = getListOfBookedMonths(props.ownEvents);
        // }

        // Save roomId, so if the user navigates back, this should be loaded
        context.saveLastRoomId(props.roomId);
        // if (uniqueListOfMonths.length > 1) {
        //   // Reactivate this, when rate payments are resolved
        // props.history.push("/betalingsrater");

        props.history.push('/opsummering');
        // } else {
        //   props.history.push("/opsummering");
        // }
    };

    const createEventListItem = useCallback(() => {
        let listOfRooms = props.ownEvents.map((event) =>
            getLocationNameFromRoomId(event.roomId)
        );
        if (listOfRooms === undefined) {
            listOfRooms = [];
        }

        const onlyDraftEvents = props.ownEvents.filter(
            (event) => event.state === 'draft'
        );

        setDraftEvents(onlyDraftEvents);

        const uniqueListOfRooms = [...new Set(listOfRooms)];
        setListOfRooms(uniqueListOfRooms);

        Promise.all(
            uniqueListOfRooms.map(async (room) => {
                const result = await getAndReturnDataAndResponse(
                    `/api/v1/users/${getUserId()}/events/${getProperRoomId(
                        room
                    )}`
                );
                // roomsBookedHours.push()
                if (result.response.status === 200) {
                    bookedHoursList.push({
                        roomId: getProperRoomId(room),
                        bookedHours: result.data.bookedHours
                    });
                } else {
                    console.log(result);
                }
                return result;
            })
        );

        setRoomsBookedHoursList(bookedHoursList);
        // eslint-disable-next-line
    }, [props.ownEvents]);

    const roomBookedHours = (roomId) => {
        let x = roomsBookedHoursList.find((room) => {
            return getProperRoomId(roomId) === room.roomId;
        });

        if (x !== undefined) {
            return `${x.bookedHours}`;
        } else {
            return 0;
        }
    };

    const handleEditClick = (eventId, date, roomId) => {
        let path;
        if (window.location.href.includes('booking-kalender')) {
            path = 'booking-kalender';
        } else {
            path = 'aktivitets-kalender';
        }
        if (props.calendarType === 'rooms') {
            // const locationName = getLocationNameFromRoomId(roomId);
            // props.getSelectedRoom(locationName);
            props.editBookedEvent(eventId);
            context.saveEventIdToEdit(eventId);

            // props.history.push(`/kalender/${locationName}?date=${date}`);
            let path;
            if (window.location.href.includes('booking-kalender')) {
                path = 'booking-kalender';
            } else {
                path = 'aktivitets-kalender';
            }

            window.location.href = `/${path}/1?date=${date}`;
        } else {
            const locationName = getLocationNameFromRoomId(roomId);
            props.getSelectedRoom(locationName);
            props.editBookedEvent(eventId);
            context.saveEventIdToEdit(eventId);
            // props.history.push(`/kalender/${locationName}?date=${date}`);
            window.location.href = `/${path}/${locationName}?date=${date}`;
        }
    };

    const handleDeleteClick = (eventId, repeatSeqId) => {
        props.confirmRemovingEvent(eventId, repeatSeqId);
    };

    const handleError = (err) => {
        console.log('error', err);
    };

    // ComponentDidMount
    useEffect(() => {
        const calculateTotalPrice = async () => {
            const draftEvents = props.ownEvents.filter(
                (event) => event.state === 'draft'
            );
            const arrayOfEventsIds = draftEvents.map((ev) => {
                return ev.id;
            });

            if (arrayOfEventsIds && arrayOfEventsIds.length > 0) {
                const totalPrices = await returnTotalPrices(
                    arrayOfEventsIds,
                    handleError
                );

                if (totalPrices && totalPrices.normalPrice) {
                    // Price is coming in øre, therefore we need to convert it into DKK.
                    setNormalTotalPrice(totalPrices.normalPrice / 100);
                    setDiscountTotalPrice(totalPrices.normalPrice / 2 / 100);
                }
            }
        };
        createEventListItem();
        calculateTotalPrice();
    }, [props.ownEvents, createEventListItem]);

    return (
        <SummaryComp>
            <Box
                display="flex"
                mt="3rem"
                width="100%"
                justifyContent="space-between"
            >
                <Box>
                    <Box display="flex">
                        <Box mr="1rem">
                            <Typography variant="body2">
                                Samlet pris <UnderLine>uden</UnderLine>{' '}
                                medlemskab:
                            </Typography>
                        </Box>
                        <Typography variant="body2">
                            {props.ownEvents.length > 0
                                ? normalTotalPrice
                                      .toString()
                                      .replaceAll('.', ',')
                                : '0'}{' '}
                            kr.
                        </Typography>
                    </Box>

                    <Box display="flex">
                        <Box mr="1rem">
                            <Typography variant="body2">
                                Samlet pris <UnderLine>med</UnderLine>{' '}
                                medlemskab:{' '}
                            </Typography>
                        </Box>

                        <Typography variant="body2">
                            {props.ownEvents.length > 0
                                ? discountTotalPrice
                                      .toString()
                                      .replaceAll('.', ',')
                                : '0'}{' '}
                            kr.
                        </Typography>
                    </Box>
                </Box>

                <Box>
                    <RegistrationButton
                        title="Se kurv"
                        onButtonClick={() => handleRegButtonClick()}
                        width="13rem"
                        margin="0"
                        mobileMargin="1.5rem 0"
                    />
                </Box>
            </Box>

            <Box mt="3rem" mb="1rem">
                <Typography variant="h6">Dine valgte reservationer:</Typography>
            </Box>

            <EventsContainer>
                {listOfRooms.map((room) => (
                    <Event key={room} mb="1rem">
                        <LocationName style={{ marginBottom: '1rem' }}>
                            Lokale {room} er i alt reserveret i{' '}
                            {roomBookedHours(room)
                                .toString()
                                .replaceAll('.', ',')}{' '}
                            timer.
                        </LocationName>
                        {draftEvents.map((event) => {
                            if (
                                getLocationNameFromRoomId(event.roomId) === room
                            ) {
                                return (
                                    <EventItem
                                        key={event.id}
                                        event={event}
                                        handleDeleteClick={() =>
                                            handleDeleteClick(
                                                event.id,
                                                event.repeatSeqId
                                            )
                                        }
                                        canBeDeleted={true}
                                        handleEditClick={handleEditClick}
                                    />
                                );
                            } else {
                                return null;
                            }
                        })}
                    </Event>
                ))}
            </EventsContainer>
        </SummaryComp>
    );
}

export default withRouter(BookingSummary);
