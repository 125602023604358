import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import 'moment/locale/da';

import LeftArrow from './arrow/LeftArrow';
import RightArrow from './arrow/RightArrow';
import Day from './day/Day';
import RoomSelect from './room-select/RoomSelect';

import '../../index.css';

// Helper functions
import {
    buildCalendar,
    getEndDate,
    getStartDate,
    getWeekNumber
} from './CalendarFunctions';
import { fetchAndFilterEvents, sortEvents } from './EventFunctions';

// MUI components
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link, withRouter } from 'react-router-dom';
import { Context } from '../../context/Context';
import { withContext } from '../containers/ContextContainer';

import {
    getEventIdFromStorage,
    getProperRoomId,
    getUserId,
    returnAuthenticated,
    getUserEmail,
    getOwnEvents,
    returnRepeatSeqId,
    getDateFromQuery,
    hasPrevPageDestination,
    getPrevPageDestination,
    getPrevPageUrl
} from '../../context/getData';
import {
    deleteAndReturnResp,
    getAndReturnData,
    postAndReturnResp
} from '../../context/helperFunctions';
import ErrorDialog from '../dialogs/error-dialog/ErrorDialog';
import DeleteDialog from '../dialogs/delete-dialog/DeleteDialog';
import ReturnLinkReusable from '../links/ReturnLinkReusable';
import SuccessBookingDialog from '../dialogs/success-booking-dialog/SuccessBookingDialog';
import BookingSummary from './booking-summary/BookingSummary';
import ConflictingEventsDialog from '../dialogs/conflicting-events-dialog/ConflictingEventsDialog';
import SwitchButtonCalendar from '../switch-button/SwitchButtonCalendar';
import ActivityOrRoomCalendarButton from './activity-or-room-calendar-button/ActivityOrRoomCalendarButton';

const CalendarContainer = styled.div`
    margin-bottom: 5rem;
    @media (min-width: 960px) {
        display: flex;
        flex-basis: 100%;
        flex-wrap: nowrap;
        min-height: 50vh;
    }
`;

const FlexContainer = styled(Box)`
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    width: 100%;
`;

const UpperFlexContainer = styled(Box)`
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
`;

const CalendarHeadlineContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const MiddleColumn = styled.div`
    @media (min-width: 960px) {
        width: 85%;
        margin: 0 auto;
    }
`;

const BacklinkContainer = styled(Box)`
    cursor: pointer;
`;

const LeftSideColumn = styled.div`
    position: absolute;
    width: 5%;
    left: 1rem;
`;

const RightSideColumn = styled.div`
    position: absolute;
    width: 5%;
    right: 1rem;
`;

const MainContainer = styled.div`
    margin-top: 4rem;
    display: block;
    @media (min-width: 960px) {
        width: 100%;
        margin: 0rem auto;
        position: relative;
    }

    @media (max-width: 960px) {
        display: none;
    }
`;

const WeekContainer = styled(Box)`
    @media (min-width: 960px) {
        display: block;
        position: absolute;
        top: 20rem;
        left: 0.5rem;
    }
`;

const CalendarTitle = styled(Typography)`
    margin-top: 0.5rem;
    font-size: 1.2rem;
    text-align: left;
    @media (min-width: 960px) {
        font-size: 1.5rem;
    }
`;

const UnderlinedText = styled.span`
    text-decoration: underline;
`;

const MonthContainer = styled.div`
    width: 100vw;
    background-color: ${(props) => props.theme.palette.secondary.light};
    position: relative;
    left: -1rem;
    padding: 1rem 2rem;
    margin: 2rem 0;

    @media (min-width: 960px) {
        display: none;
    }
`;

const MonthName = styled(Typography)`
    font-family: 'Benton Sans Medium';
`;

const ExplainTextContainer = styled(Box)`
    @media (min-width: 960px) and (max-width: 1100px) {
        width: 90%;
    }
`;

class DesktopCalendar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dateObject: moment(),
            dates: [],
            weekNumber: '',
            today: '',
            chosenRoom: 1,
            events: [],
            monthName: '',
            isLoading: false,
            startDate: '',
            endDate: '',
            roomId: 1,
            isBookingCalendar: false,

            // Events that the user created
            ownDraftEvents: [],

            //Error dialog
            error: false,
            errorMessage: '',

            // Success dialog
            showSuccessBooking: false,
            // Events, which has been created after submiting a request -  These are displayed in the success dialog
            justBookedEvents: [],

            //Conflict booking dialog
            showConflictBooking: false,
            // Events, which has been created after submiting a request -  These are displayed in the conflict events dialog
            conflictingEvents: [],
            conflictPostRequestBody: {},

            //Dialog for confirming the deletion of booking
            deleteDialog: false,
            deleteDialogMessage: '',
            selectedEventIdToDelete: null,
            multipleEvents: false,

            // Editing a selected event
            selectedEventIdToEdit: null
        };
    }

    static contextType = Context;
    colors = ['blue', 'purple', 'green', 'orange', 'yellow'];

    // Set date object, dates and weekNumber when arrows are clicked
    setDateObject = (week) => {
        this.setState(
            {
                dateObject: week,
                dates: buildCalendar(week),
                weekNumber: getWeekNumber(week),
                startDate: getStartDate(week),
                endDate: getEndDate(week)
            },
            () => {
                fetchAndFilterEvents(
                    this.state.isBookingCalendar,
                    this.state.chosenRoom,
                    this.state.startDate,
                    this.state.endDate,
                    this.handleError,
                    this
                );
            }
        );

        // Change date in url query param too
        let path;
        if (window.location.href.includes('booking-kalender')) {
            path = 'booking-kalender';
        } else {
            path = 'aktivitets-kalender';
        }
        this.props.history.push(
            `/${path}/${this.state.chosenRoom}?date=${moment(week).format(
                'YYYY-MM-DD'
            )}`
        );
    };

    // Come back: handle error
    handleError = (message) => {
        if (message && message !== '') {
            this.setState({
                error: true,
                errorMessage: message
            });
        } else {
            this.setState({
                error: true,
                errorMessage: 'Noget gik galt. Prøv at opdatere siden.'
            });
        }
    };

    setJustBookedEvents = (events) => {
        this.setState({ justBookedEvents: events, showSuccessBooking: true });
    };

    setConflictingEvents = (events) => {
        this.setState({ conflictingEvents: events, showConflictBooking: true });
    };

    // Get selected room and change the events rendered in calendar according to the it
    getSelectedRoom = async (room) => {
        this.setState({ chosenRoom: Number(room) }, () => {
            fetchAndFilterEvents(
                this.state.isBookingCalendar,
                this.state.chosenRoom,
                this.state.startDate,
                this.state.endDate,
                this.handleError,
                this
            );
            this.setState({ roomId: getProperRoomId(this.state.chosenRoom) });
        });
    };

    // filterData = (dataFromApi, isBookingCalendar, ownEvents) => {
    //     //Should filter incoming data
    //     const filteredData = dataFromApi.filter(
    //         (event) => event.roomId === getProperRoomId(this.state.chosenRoom)
    //     );

    //     const events = createEvents(filteredData, isBookingCalendar, ownEvents);
    //     this.setState({ events });
    // };

    // fetchAndFilterEvents = async (isBookingCalendar) => {
    //     console.log('fetching and filtering events');
    //     // Return the right roomId for the selected room - in database the roomId is not the same as the number in the name of the room

    //     const properRoomId = getProperRoomId(this.state.chosenRoom);

    //     const data = await fetchEvents(
    //         properRoomId,
    //         this.state.startDate,
    //         this.state.endDate,
    //         this.handleError
    //     );

    //     const ownEvents = await getOwnEvents(() => {
    //         console.log('error when getting own events');
    //     });
    //     if (isBookingCalendar) {
    //         this.filterData(data, isBookingCalendar, ownEvents);
    //     } else {
    //         this.filterData(data, this.state.isBookingCalendar, ownEvents);
    //     }
    // };

    // User created a new event, we want to store it in an array, in order to display it below the calendar
    addNewOwnEvent = (events) => {
        // events.forEach((event) => {
        //   const eventsFromStorage = getEvents() ? getEvents() : [];
        //   event.locationName = getLocationNameFromRoomId(event.roomId);
        //   console.log("events from storage", eventsFromStorage);
        //   // Add new event
        //   // eventsFromStorage.push(event);
        //   // this.context.saveEvents(eventsFromStorage);
        //   // this.setState({ ownEvents: eventsFromStorage }, () => {});
        //   // // If there is an event id to edit saved to storage, remove that
        //   // if (getEventIdFromStorage() && getEventIdFromStorage() !== 0) {
        //   //   this.context.saveEventIdToEdit(0);
        //   // }
        // });
    };

    // Updates the list of own events
    // updateOwnEventsList = (updatedEventsArray) => {
    //   this.setState({ ownEvents: updatedEventsArray });
    // };

    getOwnEventObjects = (eventIds, events) => {
        const ownEventObjects = [];
        for (let i = 0; i < eventIds.length; i++) {
            events.forEach((event) => {
                if (event.event_id === eventIds[i]) {
                    ownEventObjects.push(event);
                }
            });
        }
        return ownEventObjects;
    };

    // A dialog is displayed to make the user confirm the deletion
    // If they press yes, the removeBookedEvent will be run
    confirmRemovingEvent = async (eventId, repeatSeqId) => {
        console.log('confirm removing');
        if (repeatSeqId) {
            // Check if there are multiple events to delete
            // If there are, we need to ask the user,  if they want to delete all related events
            const data = await getAndReturnData(
                `/api/v1/events/${repeatSeqId}`,
                this.handleError
            );
            if (data && data.events && data.events.length > 1) {
                this.setState({
                    deleteDialog: true,
                    selectedEventIdToDelete: eventId,
                    multipleEvents: true
                });
            } else {
                this.setState({
                    deleteDialog: true,
                    selectedEventIdToDelete: eventId
                });
            }
        } else {
            this.setState({
                deleteDialog: true,
                selectedEventIdToDelete: eventId
            });
        }
        this.updateOwnEvents();
    };

    // User is able to delete its own event by confirming the deletion in the DeleteDialog
    removeBookedEvent = async (eventId, repeatSeqId) => {
        const getUrl = () => {
            if (repeatSeqId !== undefined) {
                return `/api/v1/users/${getUserId()}/events/draft/${eventId}?repeatSeqId=${repeatSeqId}`;
            } else {
                return `/api/v1/users/${getUserId()}/events/draft/${eventId}`;
            }
        };
        const resp = await deleteAndReturnResp(getUrl(), this.handleError);
        if (resp && resp.status === 200) {
            this.updateOwnEvents();
            this.setState({ deleteDialog: false });
        } else {
            this.handleError(
                'Noget gik galt. Kunne ikke fjerne reservationen.  Prøv igen.'
            );
            this.setState({ deleteDialog: false });
        }

        // Update events by getting events data from backend, and update own events.
        fetchAndFilterEvents(
            this.state.isBookingCalendar,
            this.state.chosenRoom,
            this.state.startDate,
            this.state.endDate,
            this.handleError,
            this
        );
        this.updateOwnEvents(this, this.handleError);
    };

    editBookedEvent = async (eventId) => {
        this.setState({ selectedEventIdToEdit: eventId });
    };

    removeEdit = () => {
        this.setState({ selectedEventIdToEdit: null });
        if (getEventIdFromStorage()) {
            this.context.saveEventIdToEdit(0);
        }
    };

    updateOwnEvents = async () => {
        if (returnAuthenticated()) {
            const ownEvents = await getOwnEvents(this.handleError);
            if (ownEvents) {
                const onlyDrafts = ownEvents.filter(
                    (event) => event.state === 'draft'
                );
                if (onlyDrafts) {
                    this.setState({ ownDraftEvents: sortEvents(onlyDrafts) });
                }
            }
        }
    };

    componentDidMount() {
        // Get room id from path parameters
        if (
            this.props &&
            this.props.match &&
            this.props.match.params &&
            this.props.match.params.id
        ) {
            this.setState(
                {
                    chosenRoom: Number(this.props.match.params.id)
                },
                () => {
                    this.setState({
                        roomId: getProperRoomId(this.state.chosenRoom)
                    });
                }
            );
        }

        let isBookingCal;
        if (window.location.href.includes('booking-kalender')) {
            isBookingCal = true;
            this.setState({ isBookingCalendar: true });
        } else {
            isBookingCal = false;
            this.setState({ isBookingCalendar: false });
        }

        // Get dateobject from query Url. That shows, what week we should show
        const dateFromQuery = getDateFromQuery(this);
        let dateObject;
        if (dateFromQuery) {
            dateObject = dateFromQuery;
        } else {
            dateObject = this.state.dateObject;
        }

        // Build calendar, and get events, available times
        this.setState(
            {
                dateObject,
                dates: buildCalendar(dateObject),
                weekNumber: getWeekNumber(dateObject),
                startDate: getStartDate(dateObject),
                endDate: getEndDate(dateObject)
            },
            () => {
                // Filter data to default room, use fetched data here
                fetchAndFilterEvents(
                    isBookingCal,
                    this.state.chosenRoom,
                    this.state.startDate,
                    this.state.endDate,
                    this.handleError,
                    this
                );
            }
        );

        this.updateOwnEvents();

        // Check if there is any event id to edit in local storage
        if (getEventIdFromStorage() && getEventIdFromStorage() !== 0) {
            this.setState({ selectedEventIdToEdit: getEventIdFromStorage() });
        }
    }

    // Sending the request body, which is needed to create a recurring booking, if there is conflicts,
    // and the user wants to create the events anyways.
    setConflictPostRequestBody = (requestBody) => {
        this.setState({ conflictPostRequestBody: requestBody });
    };

    bookNonConflictingEvents = async () => {
        // See setConflictingPostRequestBody to modify the requestBody
        const response = await postAndReturnResp(
            '/api/v1/events/non-conflicting-events/booking', // Here the new endpoint should be implemented
            this.state.conflictPostRequestBody,
            this.handleError
        );

        if (!response || response.status !== 200) {
            return this.handleError();
        }

        if (response && response.status === 200) {
            const data = await response.json();
            if (
                // This part is dependent on the endpoint, how the data is being returned
                data &&
                data.createdEvents &&
                data.createdEvents.length > 0 &&
                data.createdEvents[0].id
            ) {
                // This is needed to refresh the events in the calendar.
                fetchAndFilterEvents(
                    this.state.isBookingCalendar,
                    this.state.chosenRoom,
                    this.state.startDate,
                    this.state.endDate,
                    this.handleError,
                    this
                );
                // This is needed to be able to display in the coming dialog of what events has been created
                this.setJustBookedEvents(data.createdEvents);
            }
        }
    };

    fetchAndFilterEventsInCalendar = () => {
        fetchAndFilterEvents(
            this.state.isBookingCalendar,
            this.state.chosenRoom,
            this.state.startDate,
            this.state.endDate,
            this.handleError,
            this
        );
    };

    render() {
        return (
            <MainContainer checked={this.props.checked}>
                <Box display="flex">
                    <LeftSideColumn>
                        <WeekContainer>
                            <Typography variant="body2">Uge</Typography>
                            <Typography variant="body2">
                                {this.state.weekNumber}
                            </Typography>
                        </WeekContainer>
                        <LeftArrow
                            setDateObject={this.setDateObject}
                            dateObject={this.state.dateObject}
                            top="25rem"
                        />
                    </LeftSideColumn>
                    <MiddleColumn>
                        <CalendarHeadlineContainer>
                            <Box width="100%">
                                {hasPrevPageDestination(this.props) ? (
                                    <ReturnLinkReusable
                                        destination={getPrevPageDestination(
                                            this.props
                                        )}
                                        url={getPrevPageUrl(this.props)}
                                        marginBottom="2rem"
                                    />
                                ) : (
                                    <BacklinkContainer
                                        width="fit-content"
                                        onClick={() => {
                                            this.props.history.goBack();
                                            this.props.history.goBack();
                                        }}
                                    >
                                        <ReturnLinkReusable marginBottom="2rem" />
                                    </BacklinkContainer>
                                )}
                                <UpperFlexContainer>
                                    <CalendarTitle
                                        variant="h4"
                                        gutterBottom
                                        m="2"
                                        mb="1.5rem"
                                        component="h1"
                                        className="calendar-title"
                                    >
                                        {this.state.isBookingCalendar
                                            ? 'Lej lokale'
                                            : 'Aktivitetsoversigt for Bülowsvej 10'}
                                    </CalendarTitle>
                                    <ActivityOrRoomCalendarButton />
                                </UpperFlexContainer>

                                {this.state.isBookingCalendar ? (
                                    <ExplainTextContainer>
                                        <Typography variant="body2">
                                            Når du vil reservere et lokale, kan
                                            du vælge mellem to visninger:
                                            <br />
                                            Ugevisning: En ugeoversigt for et
                                            lokale ad gangen – skift lokale i
                                            rullemenuen.
                                            <br />
                                            Lokalevisning: En dagsoversigt over
                                            alle lokaler – skift dag med
                                            piletast eller minikalenderen.
                                        </Typography>
                                    </ExplainTextContainer>
                                ) : (
                                    <ExplainTextContainer>
                                        <Typography variant="body2">
                                            Dette er hele husets
                                            aktivitetsoversigt.
                                            <br /> Medlemmer kan bruge
                                            oversigten til at se hvilke lokaler,
                                            der er ledige.
                                            <br /> Hold musen over en aktivitet
                                            for at læse mere om arrangøren.{' '}
                                            <br />
                                            Ønsker du at foretage en reservation
                                            af et lokale? Klik
                                            <Link to="/booking-kalender">
                                                {' '}
                                                <UnderlinedText>
                                                    her
                                                </UnderlinedText>
                                            </Link>
                                            .
                                        </Typography>
                                    </ExplainTextContainer>
                                )}
                            </Box>
                        </CalendarHeadlineContainer>
                        <FlexContainer display="flex">
                            <Box>
                                <RoomSelect
                                    getSelectedRoom={this.getSelectedRoom}
                                    chosenRoom={this.state.chosenRoom}
                                />
                            </Box>
                            {document.body.offsetWidth > 960 && (
                                <Box mt="2rem">
                                    <SwitchButtonCalendar
                                        handleChange={
                                            this.props.handleCalendarChange
                                        }
                                        checked={this.props.checked}
                                    />
                                </Box>
                            )}
                        </FlexContainer>

                        <CalendarContainer>
                            {this.state.dates.map((day) => {
                                this.color = this.colors[
                                    Math.floor(
                                        Math.random() * this.colors.length
                                    )
                                ];
                                while (
                                    this.color === this.oldColor ||
                                    this.color === 'yellow'
                                ) {
                                    this.color = this.colors[
                                        Math.floor(
                                            Math.random() * this.colors.length
                                        )
                                    ];
                                }
                                this.oldColor = this.color;
                                return day.isMonthName ? (
                                    <MonthContainer key={day.key}>
                                        <MonthName variant="h6">
                                            {day.monthName}
                                        </MonthName>
                                    </MonthContainer>
                                ) : (
                                    <Day
                                        key={day.dateTimeValue}
                                        dayName={day.name}
                                        dayNameShort={day.nameShort}
                                        date={day.date}
                                        displayDate={day.displayDate}
                                        displayDateShort={day.displayDateShort}
                                        isToday={day.isToday}
                                        events={this.state.events}
                                        roomId={this.state.roomId}
                                        fetchAndFilterEventsInCalendar={
                                            this.fetchAndFilterEventsInCalendar
                                        }
                                        // addNewOwnEvent={this.addNewOwnEvent}
                                        selectedEventIdToEdit={
                                            this.state.selectedEventIdToEdit
                                        }
                                        editBookedEvent={this.editBookedEvent}
                                        removeEdit={this.removeEdit}
                                        // updateOwnEventsList={this.updateOwnEventsList}
                                        handleError={this.handleError}
                                        ownEvents={this.state.ownDraftEvents}
                                        confirmRemovingEvent={
                                            this.confirmRemovingEvent
                                        }
                                        setJustBookedEvents={
                                            this.setJustBookedEvents
                                        }
                                        setConflictingEvents={
                                            this.setConflictingEvents
                                        }
                                        setConflictPostRequestBody={
                                            this.setConflictPostRequestBody
                                        }
                                        updateOwnEvents={this.updateOwnEvents}
                                        // dayColor={this.color}
                                        isBookingCalendar={
                                            this.state.isBookingCalendar
                                        }
                                    />
                                );
                            })}
                        </CalendarContainer>

                        {returnAuthenticated() &&
                            this.state.isBookingCalendar && (
                                <>
                                    {getUserEmail() !==
                                        'admin@denlillemusikskole.dk' &&
                                        getUserEmail() !==
                                            '1@denlillemusikskole.dk' && (
                                            <BookingSummary
                                                ownEvents={
                                                    this.state.ownDraftEvents
                                                }
                                                confirmRemovingEvent={
                                                    this.confirmRemovingEvent
                                                }
                                                editBookedEvent={
                                                    this.editBookedEvent
                                                }
                                                chosenRoom={
                                                    this.state.chosenRoom
                                                }
                                                getSelectedRoom={
                                                    this.getSelectedRoom
                                                }
                                                roomId={this.state.roomId}
                                            />
                                        )}
                                </>
                            )}
                    </MiddleColumn>
                    <RightSideColumn>
                        <RightArrow
                            setDateObject={this.setDateObject}
                            dateObject={this.state.dateObject}
                            top="25rem"
                        />
                    </RightSideColumn>
                </Box>

                {this.state.isLoading && (
                    <Box display="flex" justifyContent="center" pt="2rem">
                        <CircularProgress color="primary.contrastText" />
                    </Box>
                )}

                {this.state.error && (
                    <ErrorDialog
                        dialogTitle=""
                        close={() =>
                            this.setState({ error: false, errorMessage: '' })
                        }
                        errorMessage={this.state.errorMessage}
                    />
                )}

                {this.state.showSuccessBooking && (
                    <SuccessBookingDialog
                        events={this.state.justBookedEvents}
                        close={() => {
                            if (this.state.conflictingEvents.length > 0) {
                                window.location.reload(); // come back later and remove this!!
                            } else {
                                this.setState({
                                    showSuccessBooking: false,
                                    showConflictBooking: false,
                                    justBookedEvents: [],
                                    conflictPostRequestBody: {},
                                    conflictingEvents: []
                                });
                            }

                            // window.location.reload(); // come back later and remove this!!
                        }}
                    />
                )}

                {this.state.showConflictBooking && (
                    <ConflictingEventsDialog
                        conflictingEvents={this.state.conflictingEvents}
                        bookNonConflictingEvents={this.bookNonConflictingEvents}
                        close={() => {
                            this.setState({
                                showConflictBooking: false,
                                conflictingEvents: []
                            });
                        }}
                    />
                )}

                {this.state.deleteDialog && (
                    <DeleteDialog
                        close={() =>
                            this.setState({
                                deleteDialog: false,
                                selectedEventIdToDelete: null,
                                multipleEvents: false
                            })
                        }
                        eventId={this.state.selectedEventIdToDelete}
                        repeatSeqId={returnRepeatSeqId(
                            this.state.ownDraftEvents,
                            this.state.selectedEventIdToDelete
                        )}
                        removeBookedEvent={this.removeBookedEvent}
                        multipleEvents={this.state.multipleEvents}
                        isDraftEvent={true}
                        ownEvents={this.state.ownDraftEvents}
                    />
                )}
            </MainContainer>
        );
    }
}

export default withContext(withRouter(DesktopCalendar));
