import React, { Component } from 'react';
import styled from 'styled-components';

import RegularButton from '../../buttons/Button';
import SimpleTimePicker from '../simple-time-picker/SimpleTimePicker';
import { Box, Typography } from '@material-ui/core';
import {
    getAndReturnData,
    postAndReturnResp,
    putAndReturnRespWithAuth
} from '../../../context/helperFunctions';
import TextInput from '../../text-input/TextInput';
import { setTimesForEditingEvents } from '../EventFunctions';
import {
    getEventIdFromStorage,
    getToken,
    getUserEmail,
    getUserId,
    hasPublicProfile,
    returnAuthenticated
} from '../../../context/getData';
import { Context } from '../../../context/Context';
import { isStartTimeLaterThanEnd } from '../BookingFunctions';
import UpdateAllEventsDialog from '../../dialogs/update-all-events-dialog/UpdateAllEventsDialog';

import moment from 'moment';
import ErrorDialog from '../../dialogs/error-dialog/ErrorDialog';
import CloseIcon from '@material-ui/icons/Close';
import CheckboxComponent from '../../checkbox/CheckboxComponent';
import Questionmark from '../../questionmark/Questionmark';
import SelectRepeatdays from '../../select-repeatdays/SelectRepeatdays';
import DatePickerComponent from '../../date-picker/DatePicker';
import AskForLoginDialog from '../../dialogs/ask-for-login-dialog/AskForLoginDialog';
import { getLocalStorageJson } from '../../../util';

const AvailableEventContainer = styled.div`
  background-color: ${(props) => props.theme.palette.secondary.light};
  padding: 0.8rem 0.6rem;
  position: relative;
  z-index: 0;
  /* ${(props) => (props.isexpanded ? 2 : 0)}; */
  @media (min-width: 960px) {
    padding: 0;
    margin: 0.8rem 0.6rem;
    background-color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const PickerTitle = styled(Typography)`
    font-size: 0.8rem;
    color: ${(props) =>
        props.haserror === 'true'
            ? props.theme.palette.error.main
            : props.theme.palette.primary.contrastText};
`;

const DatePickerTitle = styled(Typography)`
    font-size: 0.8rem;
    color: ${(props) =>
        props.haserror === 'true'
            ? props.theme.palette.error.main
            : props.theme.palette.colors.orange};
`;

const CloseIconContainer = styled.div`
    .MuiSvgIcon-root {
        width: 0.6em;
        height: 0.6em;
    }
    font-size: 1rem;
    margin: 0;
    padding: 0;
    position: absolute;
    right: -0.1rem;
    top: 0;
    cursor: pointer;
    @media (max-width: 960px) {
        font-size: 1rem;
        top: 0.8rem;
        right: 1rem;
    }
`;
const ButtonContainer = styled.div`
    margin: 2rem auto 1rem auto;
`;

const CustomFormControl = styled.div`
    margin: 1rem 0;
`;

const TimeHeadline = styled(Typography)`
    cursor: ${(props) => (props.auth ? 'pointer' : 'default')};
    font-size: 0.8rem;
    @media (max-width: 1200px) {
        margin-right: 17px;
    }
`;

const AvailableEventTitle = styled(Typography)`
    cursor: ${(props) => (props.auth ? 'pointer' : 'default')};
    font-size: 0.9rem;
    text-decoration: underline;
`;

const CheckboxText = styled(Typography)`
    margin-top: 6px;
    font-size: 0.8rem;

    @media (max-width: 1251px) {
        margin-top: 0.5rem;
    }
`;

const QuestionmarkContainer = styled.span`
    margin-top: 0.5rem;

    img {
        margin-bottom: 4px;
    }
`;

const CheckboxWithQuestionmark = styled.div`
    display: flex;
`;
class AvailableTimeSlot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isExpanded: false,
            newEventStartTime: '',
            newEventEndTime: '',
            isEndTimeChosen: false,
            automaticEndTime: '',
            startTimeError: false,
            endTimeError: false,
            startHour: '',
            startMinute: '',
            endHour: '',
            endMinute: '',
            activityName: '',
            inputError: false,
            inputErrorText: '',
            newAvailableEndTime: '',
            newAvailableStartTime: '',
            originalEndTime: '',
            originalStartTime: '',
            repeatdays: 0,
            enddate: new Date(this.props.date),
            startdate: new Date(this.props.date),
            repeatSeqId: '',
            isPrivate: false, // If this is false, then the opslagstavle profile is going to be shown when hovering the event

            bookWhenLogout: false,
            dateError: false,
            dateErrorWarning: false,
            errorMessage: '',
            // isAuthenticated: false,

            showLoginPrompt: false,
            showUpdateAllEventsDialog: false,
            firstEventStartDateInState: '',
            firstEventIdInState: null
        };
    }

    static contextType = Context;

    toggleBookingMode = () => {
        // User should only be able to open the available time slot, if logged in
        if (returnAuthenticated()) {
            if (this.props.existingEvent) {
                this.props.removeEdit();
            }
            this.setState({ isExpanded: !this.state.isExpanded });
            // if (!this.props.roomsCalendar) {
            this.props.setIsExpanded(
                !this.state.isExpanded,
                this.props.date,
                this.props.startTime,
                this.props.roomId
            );
            // }
        } else {
            this.setState({ bookWhenLogout: true });
        }
    };

    onInputChange = (valueObject) => {
        this.setState({ activityName: valueObject.value });
    };

    updateDraftEvents = async (
        updateMultipleEvents,
        firstEventStartDate,
        firstEventId
    ) => {
        const token = getToken();
        const eventFromStorage = this.props.ownEvents.filter(
            (event) => event.id === this.props.selectedEventIdToEdit
        );
        // Need to check if updateMultipleEvents is true or false

        const shouldUpdateAllEvents = (updateMultipleEvents) => {
            if (updateMultipleEvents) {
                return true;
            } else {
                return false;
            }
        };

        let requestBody, response;

        if (updateMultipleEvents) {
            // updateAllEvents should be set to true for both single and multiple event update
            requestBody = {
                startdate: firstEventStartDate,
                enddate: moment(this.state.enddate).format('YYYY-MM-DD'),
                starttime: this.state.newEventStartTime,
                endtime: this.state.newEventEndTime,
                repeatdays: this.state.repeatdays,
                title: this.state.activityName,
                updateAllEvents: shouldUpdateAllEvents(updateMultipleEvents),
                isPrivate: this.state.isPrivate
            };

            response = await putAndReturnRespWithAuth(
                `/api/v1/users/${getUserId()}/events/draft/${firstEventId}`,
                requestBody,
                token,
                this.props.handleError
            );
        } else {
            // updateAllEvents should be set to true for both single and multiple event update
            requestBody = {
                startdate: this.props.date,
                enddate: moment(this.state.enddate).format('YYYY-MM-DD'),
                starttime: this.state.newEventStartTime,
                endtime: this.state.newEventEndTime,
                repeatdays: this.state.repeatdays,
                title: this.state.activityName,
                updateAllEvents: shouldUpdateAllEvents(updateMultipleEvents),
                isPrivate: this.state.isPrivate
            };

            response = await putAndReturnRespWithAuth(
                `/api/v1/users/${getUserId()}/events/draft/${
                    eventFromStorage[0].id
                }`,
                requestBody,
                token,
                this.props.handleError
            );
        }

        if (response.status !== 200) {
            if (response.status === 409) {
                // There is a conflicting event:
                return this.props.handleError(
                    'Der er desværre optaget i dette lokale.'
                );
            }
            this.setState({ isExpanded: false });
            return this.props.handleError();
        }

        const data = await response.json();
        // Check if there is an array of events in the response body. If yes,
        // it means that new events have been created, which we should list to the user
        if (data.events && data.events.length > 0) {
            this.props.updateOwnEvents();
            this.props.fetchAndFilterEventsInCalendar();
            this.setState({ isExpanded: false }, () => {
                this.props.setJustBookedEvents(data.events);
            });
            this.props.setIsExpanded(false);
        } else {
            this.props.updateOwnEvents();
            this.props.removeEdit();
            this.setState({ isExpanded: false });
            this.props.fetchAndFilterEventsInCalendar();
            this.props.setIsExpanded(false);
        }
    };

    onSubmit = async () => {
        // Check if booking view is active / expanded
        const regex = /^[A-Za-z0-9 æøåüÆØÅÜ.,-/:()!?'"&%#=+;]+$/;
        if (this.state.isExpanded) {
            // Check if activity name is filled
            if (this.state.activityName === '') {
                this.props.handleError('Aktivitetsnavn er krævet.');
                this.setState({ inputError: true, inputErrorText: 'Krævet' });
            } else if (this.state.activityName.length > 64) {
                this.props.handleError(
                    'Aktivitetsnavn kan max være 64 karakter.'
                );
                this.setState({
                    inputError: true,
                    inputErrorText: 'Max 64 karakter'
                });
            } else if (!regex.test(this.state.activityName)) {
                this.props.handleError('Specialtegn er ikke tilladt');
                this.setState({
                    inputError: true,
                    inputErrorText: 'Specialtegn er ikke tilladt'
                });
            } else {
                // Reactivate this next year, when you want to limit booking for the next season
                // Start or end date should not be later then 31.june)
                // const systemUsers = [
                //     'admin@denlillemusikskole.dk',
                //     'test@denlillemusikskole.dk',
                //     'lk@denlillemusikskole.dk',
                //     'rj@denlillemusikskole.dk',
                //     'nlh@denlillemusikskole.dk',
                //     'jukas1979@gmail.com'
                // ];

                // // const key = document.location.hostname + ':state-history';
                // let userEmail = getUserEmail();

                // if (!systemUsers.includes(userEmail)) {
                //     if (
                //         moment(this.state.startdate).isAfter(
                //             new Date('2023-07-31') ||
                //                 moment(this.state.enddate).isAfter(
                //                     new Date('2023-07-31')
                //                 )
                //         )
                //     ) {
                //         return this.setState({
                //             dateError: true,
                //             // Danish text needs revision
                //             errorMessage:
                //                 'Det er ikke muligt at reservere efter den 31. juli'
                //         });
                //     }
                // }

                if (!this.state.startTimeError && !this.state.endTimeError) {
                    // Check if start time is later than end time
                    if (
                        isStartTimeLaterThanEnd(
                            this.state.newEventStartTime,
                            this.state.newEventEndTime
                        )
                    ) {
                        this.props.handleError(
                            'Starttidspunktet skal være tidligere end sluttidspunktet.'
                        );
                        return this.setState({ startTimeError: true });
                    } else if (
                        moment(
                            this.props.date + ' ' + this.state.newEventStartTime
                        ).isBefore(moment(new Date()))
                    ) {
                        this.setState({ startTimeError: true });
                        return this.props.handleError(
                            'Sørg for at vælge et tidspunkt i fremtiden'
                        );
                    } else if (
                        this.state.newEventStartTime ===
                        this.state.newEventEndTime
                    ) {
                        this.setState({ startTimeError: true });
                        this.props.handleError(
                            'The event must be at least 15 minutes long.'
                        );
                    } else {
                        // Check if it is an update
                        // If it is an already existing event, but it has been modified, we need to make a put request
                        if (this.props.existingEvent) {
                            // Check if event has been changed
                            // Get original event from storage with the same event Id
                            const originalEvents = this.props.ownEvents.filter(
                                (event) =>
                                    event.id ===
                                    this.props.selectedEventIdToEdit
                            );
                            if (
                                originalEvents[0].title ===
                                    this.state.activityName &&
                                originalEvents[0].starttime ===
                                    this.state.newEventStartTime &&
                                originalEvents[0].endtime ===
                                    this.state.newEventEndTime &&
                                originalEvents[0].repeatdays ===
                                    this.state.repeatdays &&
                                originalEvents[0].enddate ===
                                    moment(this.state.enddate).format(
                                        'YYYY-MM-DD'
                                    ) &&
                                originalEvents[0].repeatdays ===
                                    this.state.repeatdays &&
                                originalEvents[0].isPrivate ===
                                    this.state.isPrivate
                            ) {
                                // No change has been done, just close the available time slot

                                if (
                                    getEventIdFromStorage() &&
                                    getEventIdFromStorage !== 0
                                ) {
                                    this.context.saveEventIdToEdit(0);
                                }
                                this.setState({ isExpanded: false });
                                this.props.updateOwnEvents();
                                return this.props.removeEdit();
                            }

                            // There has been a change, need to update the database
                            // Check if it is a draft or final event
                            if (originalEvents[0].state === 'draft') {
                                // Check if it is a recurring event
                                const data = await getAndReturnData(
                                    `/api/v1/events/${this.state.repeatSeqId}`,
                                    this.handleError
                                );
                                if (
                                    data &&
                                    data.events &&
                                    data.events.length > 1
                                ) {
                                    let firstEventId = data.events[0].id;
                                    for (let item in data.events) {
                                        if (
                                            data.events[item].id < firstEventId
                                        ) {
                                            firstEventId = data.events[item].id;
                                        }
                                    }
                                    const firstEventStartDateResp = await getAndReturnData(
                                        `/api/v1/events/startdate/${this.state.repeatSeqId}/${firstEventId}`,
                                        this.handleError
                                    );

                                    let firstEventStartDate;
                                    if (
                                        firstEventStartDateResp &&
                                        firstEventStartDateResp.eventStartdate
                                            .startdate
                                    ) {
                                        firstEventStartDate =
                                            firstEventStartDateResp
                                                .eventStartdate.startdate;
                                    }

                                    // This is a repeating event
                                    // If the user has changed repeatDays, or enddate, then update all events
                                    if (
                                        originalEvents[0].enddate !==
                                            moment(this.state.enddate).format(
                                                'YYYY-MM-DD'
                                            ) ||
                                        originalEvents[0].repeatdays !==
                                            this.state.repeatdays
                                    ) {
                                        this.updateDraftEvents(
                                            true,
                                            firstEventStartDate,
                                            firstEventId
                                        );
                                    } else {
                                        // We should save the firstEvent id and firstEventStartDate to use them when updating draft from this dialog will be showing
                                        this.setState({
                                            firstEventIdInState: firstEventId,
                                            firstEventStartDateInState: firstEventStartDate
                                        });

                                        // Otherwise, we need to ask the user, if they want to edit them all.
                                        return this.setState({
                                            showUpdateAllEventsDialog: true
                                        });
                                    }
                                } else {
                                    // If it is a single event, update it
                                    this.updateDraftEvents(false);
                                }
                                this.setState({
                                    activityName: '',
                                    repeatdays: 0,
                                    enddate: this.props.date
                                });
                            } else {
                                // If it is a final event to be edited
                                // To be done
                            }
                        } else {
                            // New event
                            // Make post request to /Events/book endpoint to reserve a time slot

                            const requestBody = {
                                userId: getUserId(),
                                state: 'draft',
                                type: 'event',
                                roomId: this.props.roomId,
                                starttime: this.state.newEventStartTime,
                                endtime: this.state.newEventEndTime,
                                title: this.state.activityName,
                                repeatdays: this.state.repeatdays,
                                startdate: moment(this.props.date).format(
                                    'YYYY-MM-DD'
                                ),
                                enddate: this.state.enddate,
                                isPrivate: this.state.isPrivate
                            };

                            // console.log('show req', requestBody);

                            const response = await postAndReturnResp(
                                '/api/v1/events/booking',
                                requestBody,
                                this.props.handleError
                            );

                            if (response && response.status !== 200) {
                                if (response.status === 409) {
                                    const data = await response.json();
                                    // There is a conflicting event:
                                    const conflictingEventIds = data.conflicts.map(
                                        (event) => event.id
                                    );
                                    requestBody.conflictingEvents = conflictingEventIds;
                                    // This is going to be used from Desktop/MobileCalendar component
                                    this.props.setConflictPostRequestBody(
                                        requestBody
                                    );
                                    return this.props.setConflictingEvents(
                                        data.conflicts
                                    );
                                } else {
                                    this.props.handleError();
                                }
                            }

                            const data = await response.json();
                            // If managed to add booking, make a new request to get fresh events data
                            if (
                                data &&
                                data.createdEvents &&
                                data.createdEvents.length > 0 &&
                                data.createdEvents[0].id
                            ) {
                                this.props.fetchAndFilterEventsInCalendar();
                                this.setState({ isExpanded: false }, () => {
                                    this.props.setJustBookedEvents(
                                        data.createdEvents
                                    );
                                });
                                this.props.updateOwnEvents();
                                this.props.removeEdit();
                                this.props.setIsExpanded(false);
                                this.setState({
                                    repeatdays: 0,
                                    enddate: new Date(this.props.date),
                                    activityName: ''
                                });
                            }
                        }
                    }
                } else {
                    return this.props.handleError(
                        'Forkert start eller slut tidspunkt.'
                    );
                }
            }
        }
    };

    removeErrorOnChange = () => {
        this.setState({ inputError: false, inputErrorText: '' });
    };

    // User should only be able to edit repeatDays and date picker, if it is a single event
    // If it is a recurring event, they should delete and recreate the events.
    shouldShowRepeatdaysAndPicker = () => {
        if (!this.state.repeatSeqId) {
            return true;
        }
    };

    componentDidMount() {
        // If the event is supposed to be edited, expand it, and fill the existing values
        // if (this.props.isExpanded) {
        //     this.setState({ isExpanded: this.props.isExpanded });
        // }

        if (this.props.edit && this.props.title) {
            this.setState({ isExpanded: true, activityName: this.props.title });
        }

        if (this.props.isPrivate) {
            this.setState({ isPrivate: this.props.isPrivate });
        }

        // Set the time if user tries to edit an existing event
        if (this.props.existingEvent) {
            setTimesForEditingEvents(
                this.props.allEventsOfTheDay,
                this,
                this.props.selectedEventIdToEdit
            );
        }
    }

    setRepeatdays = (value) => {
        this.setState({ repeatdays: value });
    };

    setEnddate = (value) => {
        // Booking opened for all 14/06/2022
        const systemUsers = [
            'admin@denlillemusikskole.dk',
            'test@denlillemusikskole.dk',
            'lk@denlillemusikskole.dk',
            'rj@denlillemusikskole.dk',
            'nlh@denlillemusikskole.dk',
            'jukas1979@gmail.com'
        ];
        const key = document.location.hostname + ':state-history';
        const dataFromLocalStorage = getLocalStorageJson(key, {});
        let userEmail = getUserEmail();
        if (
            dataFromLocalStorage &&
            dataFromLocalStorage.currentUser &&
            dataFromLocalStorage.currentUser.userEmail
        ) {
            userEmail = dataFromLocalStorage.currentUser.userEmail;
        }

        if (!systemUsers.includes(userEmail)) {
            if (moment(value).isAfter(moment(new Date('2024-07-31')))) {
                this.setState({
                    dateError: true,
                    dateErrorWarning: true,
                    errorMessage: 'Slut dato skal være tidligere end 31 juli.'
                });
            }
        }
        this.setState({ enddate: value, dateErrorWarning: false });
    };

    handlePrivateEventChange = () => {
        this.setState({ isPrivate: !this.state.isPrivate });
    };

    render() {
        return (
            <>
                {(this.props.shouldBeVisible || this.props.edit) && (
                    <AvailableEventContainer isexpanded={this.state.isExpanded}>
                        <TimeHeadline
                            auth={'' + returnAuthenticated()}
                            variant="body2"
                            onClick={() => {
                                this.toggleBookingMode();
                            }}
                        >
                            {this.props.startTime.substr(0, 2) +
                                '.' +
                                this.props.startTime.substr(3, 2)}{' '}
                            -{' '}
                            {this.props.endTime.substr(0, 2) +
                                '.' +
                                this.props.endTime.substr(3, 2)}
                        </TimeHeadline>
                        <div>
                            {this.state.isExpanded ? (
                                <div>
                                    <CloseIconContainer>
                                        <CloseIcon
                                            onClick={() => {
                                                this.toggleBookingMode();
                                            }}
                                        />
                                    </CloseIconContainer>
                                    <CustomFormControl>
                                        <PickerTitle>
                                            Vælg starttidspunkt
                                        </PickerTitle>
                                        <SimpleTimePicker
                                            parentComponentSelf={this}
                                            timeType="startTime"
                                            minute={this.state.startMinute}
                                            availableStartTime={
                                                this.props.startTime
                                            }
                                            availableEndTime={
                                                this.props.endTime
                                            }
                                            // In case it is editing an existing event, and an available timeslot comes after the current event
                                            newAvailableEndTime={
                                                this.state.newAvailableEndTime
                                            }
                                            newAvailableStartTime={
                                                this.state.newAvailableStartTime
                                            }
                                            error={this.state.startTimeError}
                                            date={this.props.date}
                                            existingEvent={
                                                this.props.existingEvent
                                            }
                                            hour={this.state.startHour}
                                            originalEndTime={
                                                this.state.originalEndTime
                                            }
                                            originalStartTime={
                                                this.state.originalStartTime
                                            }
                                        />
                                    </CustomFormControl>
                                    <PickerTitle>
                                        Vælg sluttidspunkt
                                    </PickerTitle>
                                    <SimpleTimePicker
                                        parentComponentSelf={this}
                                        timeType="endTime"
                                        minute={this.state.endMinute}
                                        automaticEndTime={
                                            this.state.automaticEndTime
                                        }
                                        availableStartTime={
                                            this.props.startTime
                                        }
                                        availableEndTime={this.props.endTime}
                                        // In case it is editing an existing event, and an available timeslot comes after the current event
                                        newAvailableEndTime={
                                            this.state.newAvailableEndTime
                                        }
                                        newAvailableStartTime={
                                            this.state.newAvailableStartTime
                                        }
                                        error={this.state.endTimeError}
                                        date={this.props.date}
                                        existingEvent={this.props.existingEvent}
                                        hour={this.state.endHour}
                                        originalEndTime={
                                            this.state.originalEndTime
                                        }
                                        originalStartTime={
                                            this.state.originalStartTime
                                        }
                                    />

                                    {/* Select repeatDays */}
                                    {/* User should only be able to edit repeatDays and date picker, if it is a single event
            If it is a recurring event, they should delete and recreate the events. */}
                                    {/* {this.shouldShowRepeatdaysAndPicker() && ( */}
                                    <Box mt="1rem">
                                        <SelectRepeatdays
                                            setRepeatdays={this.setRepeatdays}
                                            repeatdays={this.state.repeatdays}
                                        />
                                    </Box>
                                    {/* )} */}

                                    {this.state.repeatdays > 0 && (
                                        <Box mt="1rem">
                                            <DatePickerTitle
                                                haserror={this.state.dateErrorWarning.toString()}
                                            >
                                                Slut gentagelse
                                            </DatePickerTitle>
                                            <DatePickerComponent
                                                enddate={this.state.enddate}
                                                setEnddate={this.setEnddate}
                                            />
                                        </Box>
                                    )}

                                    <TextInput
                                        label="Aktivitetsnavn"
                                        onInputChange={this.onInputChange}
                                        smallErrorText={
                                            this.state.inputErrorText
                                        }
                                        inputError={this.state.inputError}
                                        id="activity-name"
                                        type="text"
                                        valueFromParent={
                                            this.state.activityName
                                        }
                                        removeErrorOnChange={
                                            this.removeErrorOnChange
                                        }
                                        variant="standard"
                                        maxLength="64"
                                    />

                                    {hasPublicProfile() && (
                                        <CheckboxWithQuestionmark>
                                            <Box
                                                display="flex"
                                                position="relative"
                                            >
                                                <CheckboxComponent
                                                    checked={
                                                        !this.state.isPrivate
                                                    }
                                                    onCheckboxChange={
                                                        this
                                                            .handlePrivateEventChange
                                                    }
                                                    position="static"
                                                />

                                                <CheckboxText
                                                    variant="body2"
                                                    onClick={
                                                        this
                                                            .handlePrivateEventChange
                                                    }
                                                >
                                                    Vis kontaktoplysninger{' '}
                                                </CheckboxText>
                                            </Box>
                                            <QuestionmarkContainer>
                                                <Questionmark
                                                    width="15px"
                                                    explainerText="Brug denne funktion, hvis du ønsker at linke din aktivitet til dit opslag på opslagstavlen. Dette bliver vist i kalenderen over husets aktiviteter."
                                                    marginTop="0"
                                                />
                                            </QuestionmarkContainer>
                                        </CheckboxWithQuestionmark>
                                    )}

                                    <ButtonContainer>
                                        <RegularButton
                                            title="Bestil"
                                            size="medium"
                                            onButtonClick={() => {
                                                this.onSubmit();
                                            }}
                                        />
                                    </ButtonContainer>
                                </div>
                            ) : (
                                <>
                                    <AvailableEventTitle
                                        auth={'' + returnAuthenticated()}
                                        variant="body2"
                                        onClick={() => {
                                            this.toggleBookingMode();
                                        }}
                                    >
                                        {/* Ledig */}
                                        Reserver
                                    </AvailableEventTitle>
                                    {this.state.bookWhenLogout && (
                                        <AskForLoginDialog
                                            close={() =>
                                                this.setState({
                                                    bookWhenLogout: false
                                                })
                                            }
                                        />
                                    )}
                                </>
                            )}
                        </div>

                        {this.state.dateError && (
                            <ErrorDialog
                                dialogTitle=""
                                close={() =>
                                    this.setState({ dateError: false })
                                }
                                errorMessage={this.state.errorMessage}
                            />
                        )}

                        {this.state.showLoginPrompt && (
                            <ErrorDialog
                                dialogTitle=""
                                close={() =>
                                    this.setState({ showLoginPrompt: false })
                                }
                                errorMessage="Du skal være logget ind for at reservere."
                            />
                        )}

                        {this.state.showUpdateAllEventsDialog && (
                            <UpdateAllEventsDialog
                                close={() =>
                                    this.setState({
                                        showUpdateAllEventsDialog: false,
                                        errorMessage: ''
                                    })
                                }
                                updateDraftEvents={this.updateDraftEvents}
                                firstEventIdInState={
                                    this.state.firstEventIdInState
                                }
                                firstEventStartDateInState={
                                    this.state.firstEventStartDateInState
                                }
                            />
                        )}
                    </AvailableEventContainer>
                )}
            </>
        );
    }
}

export default AvailableTimeSlot;
