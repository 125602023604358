import moment from 'moment';
import { getOwnEvents } from './getData';

export const getColorFromTheme = (color, props) => {
    if (color === 'blue') {
        return props.theme.palette.colors.blue;
    } else if (color === 'green') {
        return props.theme.palette.colors.green;
    } else if (color === 'orange') {
        return props.theme.palette.colors.orange;
    } else if (color === 'yellow') {
        return props.theme.palette.colors.yellow;
    } else if (color === 'purple') {
        return props.theme.palette.colors.purple;
    } else {
        return props.theme.palette.colors.green;
    }
};

// Removes white space from string
export const removeWhiteSpace = (value) => {
    if (value) {
        return value.replace(/^\s+/, '').replace(/\s+$/, '');
    }
};

export const capitalize = (str) => {
    if (str && str !== '') {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    return '';
};

export const fetchWithTimeout = async (resource, options) => {
    try {
        const { timeout = 8000 } = options;
        const controller = new AbortController();
        const id = setTimeout(() => controller.abort(), timeout);
        const response = await fetch(resource, {
            ...options,
            signal: controller.signal
        });

        clearTimeout(id);

        if (response.status === 400) {
            console.log(await response.json());
        }

        return response;
    } catch (err) {
        console.log('error:', err);
    }
};

export const postAndReturnResp = async (resource, requestBody, handleError) => {
    try {
        const response = await fetchWithTimeout(resource, {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            timeout: 8000
        });
        return response;
    } catch (error) {
        // Do something with error
        console.log(error);
        if (handleError) {
            handleError();
        }
    }
};

export const getAndReturnData = async (resource, handleError) => {
    try {
        const response = await fetchWithTimeout(resource, {
            method: 'GET',

            timeout: 8000
        });
        const data = await response.json();

        return data;
    } catch (error) {
        // Do something with error
        // console.log("Error loading resource " + resource, error);
        if (handleError) {
            handleError('');
        }
    }
};
export const getAndReturnResponse = async (resource, handleError) => {
    try {
        const response = await fetchWithTimeout(resource, {
            method: 'GET',

            timeout: 8000
        });
        return response;
    } catch (error) {
        // Do something with error
        console.log('Error loading resource ' + resource, error);
        if (handleError) {
            handleError(error);
        }
    }
};

export const getAndReturnDataWithAuth = async (
    resource,
    headerAuth,
    handleError
) => {
    try {
        const response = await fetchWithTimeout(resource, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${headerAuth}`
            },
            timeout: 8000
        });
        const data = await response.json();
        return data;
    } catch (error) {
        // Do something with error
        console.log(error);
        if (handleError) {
            handleError();
        }
    }
};

export const getAndReturnDataAndResponse = async (resource, handleError) => {
    try {
        const response = await fetchWithTimeout(resource, {
            method: 'GET',

            timeout: 8000
        });
        const data = await response.json();
        const result = { data: data, response: response };
        return result;
    } catch (error) {
        // Do something with error
        handleError && handleError(error);
    }
};

export const postAndReturnData = async (resource, requestBody, handleError) => {
    try {
        const response = await fetchWithTimeout(resource, {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            timeout: 8000
        });

        const data = await response.json();
        return data;
    } catch (error) {
        // Do something with error
        console.log(error);
        // handleError();
    }
};

export const postAndReturnDataWithAuth = async (
    resource,
    requestBody,
    headerAuth,
    handleError
) => {
    try {
        const response = await fetchWithTimeout(resource, {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${headerAuth}`
            },
            timeout: 8000
        });

        const data = await response.json();
        return data;
    } catch (error) {
        // Do something with error
        console.log(error);
    }
};

export const postAndReturnResponseWithAuth = async (
    resource,
    requestBody,
    headerAuth,
    handleError
) => {
    try {
        const response = await fetchWithTimeout(resource, {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${headerAuth}`
            },
            timeout: 8000
        });

        return response;
    } catch (error) {
        // Do something with error
        console.log(error);
        if (handleError) {
            handleError();
        }
    }
};

export const postAndReturnDataAndResponse = async (
    resource,
    requestBody,
    handleError
) => {
    try {
        const response = await fetchWithTimeout(resource, {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            timeout: 8000
        });

        const data = response;
        const result = { data: data, response: response };

        return result;
    } catch (error) {
        // Do something with error
        console.log(error);
        handleError('Server Error: something went wrong');
    }
};

export const postTokenAuthorization = async (
    resource,
    requestBody,
    headerAuth,
    setError
) => {
    try {
        const response = await fetchWithTimeout(resource, {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${headerAuth}`
            },
            timeout: 8000
        });
        // console.log('show resp1', response);
        return response;
    } catch (error) {
        console.log(error);
        // if (setError && setErrorMessage) {
        //   handleRegisterErrors(error, setError, setErrorMessage);
        // }
    }
};

export const patchAndReturnResp = async (
    resource,
    requestBody,
    handleError
) => {
    try {
        const response = await fetchWithTimeout(resource, {
            method: 'PATCH',
            body: JSON.stringify(requestBody),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            timeout: 8000
        });
        if (response.status === 200) {
            return response;
        }
    } catch (error) {
        // Do something with error
        console.log(error);
        // handleError();
    }
};

export const patchAndReturnData = async (
    resource,
    requestBody,
    handleError
) => {
    try {
        const response = await fetchWithTimeout(resource, {
            method: 'PATCH',
            body: JSON.stringify(requestBody),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            timeout: 8000
        });
        if (response.status === 200) {
            return response.json();
        }
    } catch (error) {
        // Do something with error
        console.log(error);
        handleError();
    }
};

export const deleteAndReturnResp = async (resource, handleError) => {
    try {
        const response = await fetchWithTimeout(resource, {
            method: 'DELETE',
            headers: {
                Accept: 'text/plain',
                'Content-Type': 'text/plain'
            },
            // mode: "cors",
            // cache: "no-cache",
            // credentials: "same-origin",
            timeout: 8000
        });

        if (response !== undefined && response.status === 200) {
            return response;
        }
    } catch (error) {
        console.log(error);
        handleError();
    }
};

export const deleteAndReturnRespWithAuth = async (
    resource,
    headerAuth,
    handleError
) => {
    try {
        const response = await fetchWithTimeout(resource, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${headerAuth}`
            },
            timeout: 8000
        });
        // if (response.status === 200) {
        //   return response;
        // }
        return response;
    } catch (error) {
        // Do something with error
        console.log(error);
        handleError();
    }
};

export const deleteAndReturnRespWithNewData = async (resource, handleError) => {
    try {
        const response = await fetchWithTimeout(resource, {
            method: 'DELETE',
            timeout: 8000
        });

        const data = await response.json();
        const result = { data: data, response: response };
        return result;
        // if (response.status === 200) {
        //   return response;
        // }
    } catch (error) {
        // Do something with error
        console.log(error);
        handleError();
    }
};

export const deleteAndReturnRespWithNewDataAuth = async (
    resource,
    headerAuth,
    handleError
) => {
    try {
        const response = await fetchWithTimeout(resource, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${headerAuth}`
            },
            timeout: 8000
        });

        const data = await response.json();
        const result = { data: data, response: response };
        return result;
        // if (response.status === 200) {
        //   return response;
        // }
    } catch (error) {
        // Do something with error
        console.log(error);
        handleError();
    }
};

export const putAndReturnResp = async (resource, requestBody, handleError) => {
    try {
        const response = await fetchWithTimeout(resource, {
            method: 'PUT',
            body: JSON.stringify(requestBody),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            timeout: 8000
        });
        return response;
    } catch (error) {
        // Do something with error
        console.log(error);
        handleError();
    }
};

export const putTokenAuthorization = async (
    resource,
    requestBody,
    headerAuth,
    handleError
) => {
    try {
        const response = await fetchWithTimeout(resource, {
            method: 'PUT',
            body: JSON.stringify(requestBody),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${headerAuth}`
            },
            timeout: 8000
        });
        return response;
    } catch (error) {
        // Do something with error
        console.log(error);
        handleError();
    }
};

export const putAndReturnRespWithAuth = async (
    resource,
    requestBody,
    headerAuth,
    handleError
) => {
    try {
        const response = await fetchWithTimeout(resource, {
            method: 'PUT',
            body: JSON.stringify(requestBody),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${headerAuth}`
            },
            timeout: 8000
        });
        return response;
    } catch (error) {
        // Do something with error
        console.log(error);
        handleError();
    }
};

// If email is valid it returns true
export const validateEmail = (email) => {
    var regex =
        // eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (regex.test(email) && email.length < 250) {
        return true;
    } else {
        return false;
    }
};

export const isLoginFromCalendar = () => {
    // If the user is from a kalender page, we need to let the logindialog now,
    // because the page should be refreshed
    if (window.location.href.includes('booking-kalender')) {
        return 'booking-kalender';
    } else if (window.location.href.includes('aktivitets-kalender')) {
        return 'aktivitets-kalender';
    } else {
        return false;
    }
};

export const sortEvents = (events) => {
    if (events && events.length > 0) {
        return events.sort(
            (a, b) =>
                new Date(a.startdate + ' ' + a.starttime) -
                new Date(b.startdate + ' ' + b.starttime)
        );
    } else {
        return [];
    }
};

export const getAndSetEvents = async (setOwnEvents, allEvents) => {
    let ownEventsFromDb = await getOwnEvents(
        (err) => console.log('Error, when getting events:', err),
        allEvents
    );
    //Only show the upcoming events
    if (ownEventsFromDb === undefined) {
        ownEventsFromDb = [];
    }
    if (allEvents) {
        setOwnEvents(sortEvents(ownEventsFromDb));
    } else {
        const upcomingEvents = ownEventsFromDb.filter((event) =>
            moment(event.startdate + ' ' + event.endtime).isAfter(
                moment(new Date())
            )
        );

        setOwnEvents(sortEvents(upcomingEvents));
    }
};

export const getDayName = (date) => {
    const weekdayName =
        moment(date).locale('da').format('dddd').charAt(0).toUpperCase() +
        moment(date).locale('da').format('dddd').substr(1);
    return weekdayName;
};

export const getDateString = (date) => {
    const dateString = moment(date).format('DD/MM/YYYY');

    return dateString;
};

export const getTimeString = (starttime, endtime) => {
    const timeString =
        'kl ' +
        starttime.substr(0, 2) +
        '.' +
        starttime.substr(3, 2) +
        ' - ' +
        endtime.substr(0, 2) +
        '.' +
        endtime.substr(3, 2);

    return timeString;
};
