import React from 'react';
import styled from 'styled-components';
import { getRole } from '../../context/getData';
// import AdminRichTextEditor from "../../components/rich-text-editor/AdminRichTextEditor";
// import TextFromRichTextEditor from "../../components/rich-text-editor/TextFromRichTextEditor";
import { Typography } from '@material-ui/core';

const OuterContainer = styled.div`
    position: relative;
    margin-bottom: 2rem;
`;

const TermsOfPurchaseContainer = styled.div`
    height: 25rem;

    overflow: scroll;
    overflow-x: hidden;

    & :last-child {
        margin-bottom: 5rem;
    }
`;

const LinkText = styled.a`
    text-decoration: underline;
`;

const GradientOverlay = styled.div`
    height: 6rem;
    width: 100%;
    background-image: linear-gradient(rgba(21, 19, 19, 0), rgba(21, 19, 19, 1));
    position: absolute;
    bottom: 0;
`;

// const Underlined = styled(Typography)`
//   width: 60%;
//   text-decoration: underline;
//   @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
//     width: 90%;
//   }
// `;
const TextTitle = styled(Typography)`
    font-size: 1.2rem;
    padding: 0.8rem 0rem 0.1rem 0rem;
    color: ${(props) => props.theme.palette.colors.orange};
    width: 80%;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 90%;
    }
    @media (max-width: 350px) {
        font-size: 1rem;
    }
`;
const Text = styled(Typography)`
    padding: 0.3rem 0rem;
    width: 75%;
    font-size: 1rem;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 90%;
    }
    @media (max-width: 350px) {
        font-size: 0.9rem;
    }
`;
// eslint-disable-next-line
const AdminRichTextEditorContainer = styled.div`
    margin-top: 30px;
    // margin-left:7%;
`;
// eslint-disable-next-line
const TextBold = styled(Typography)`
    width: 60%;
    font-family: 'Benton Sans Medium';
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 90%;
    }
`;
function HouseRules({ isDialog }) {
    // eslint-disable-next-line
    const role = getRole();
    // const setTextLength=(textLength)=>{
    //  return textLength
    // }

    return (
        <OuterContainer>
            {}
            {isDialog ? (
                <>
                    <TermsOfPurchaseContainer>
                        <TextTitle variant="body2">Parkering</TextTitle>
                        <Text variant="body2" gutterBottom>
                            Biler kan parkeres i gården mod betaling{' '}
                            <LinkText
                                href="https://easypark.dk/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                (Easy Park)
                            </LinkText>
                            . Parker venligst, så der også er plads til andre.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Cykler skal parkeres foran huset (ikke bagved eller
                            i gården).
                        </Text>

                        <TextTitle variant="body2">Adgang</TextTitle>
                        <Text variant="body2" gutterBottom>
                            Adgang til huset sker via bagdøren.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Døren står permanent låst og kan kun åbnes via 'Åben
                            døren'-knappen på Min side når du er logget ind.
                            <br />
                            'Åben døren'-knappen aktiveres først, når du er
                            blevet medlem og/eller lejet et lokale.
                        </Text>

                        <TextTitle variant="body2">Skofri bygning</TextTitle>
                        <Text variant="body2" gutterBottom>
                            Det meste af huset er skofrit område, skoene sættes
                            i garderoben i skoreolerne i lokalerne omkring
                            indgangsdøren.
                            <br />
                            Alternativt tages der et særligt overtræk ud over
                            skoene.
                            <br />
                            Hvis du har gæster, er det dit ansvar, at de også
                            tager skoene af.
                        </Text>

                        <TextTitle variant="body2">
                            Lokalernes stand og brug
                        </TextTitle>
                        <Text variant="body2" gutterBottom>
                            Lokalerne forlades altid i samme stand og
                            oprydningstilstand som ved ankomsten.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Vinduerne skal være lukkede, når lokalet forlades.
                            Alle aktiviteter der afgiver lyd, skal være for
                            lukkede vinduer. Der udlejes kun til relativt stille
                            aktiviteter, f.eks. brug af akustiske instrumenter
                            og sang eller anlæg på et behersket lydniveau.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Det er obligatorisk at udlufte lokalet efter brug.
                        </Text>

                        <TextTitle variant="body2">Affald</TextTitle>
                        <Text variant="body2" gutterBottom>
                            Affald tages med ned til vores affaldssortering i
                            køkkenet.
                        </Text>

                        <TextTitle variant="body2">
                            Lokalernes inventar
                        </TextTitle>
                        <Text variant="body2" gutterBottom>
                            Den Lille Musikskole, som er den største bruger af
                            lokalerne, har en del rekvisitter liggende, som ikke
                            må bruges af andre.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            NB! De farvede taburetter må KUN benyttes af børn.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Der er klaverer i alle rum. De kan bruges frit, husk
                            at lukke låget ned, når du går. Brug af andre
                            instrumenter, inventar og rekvisitter end
                            klavererne, kræver særskilt aftale med musikskolen.
                            Vi har derudover faldunderlagsmåtter, der dækker det
                            meste af arealet i det største rum. Vi har også 10
                            meditationspuder, massagebriks, samt tæpper, borde
                            og stole.
                        </Text>
                        <TextTitle variant="body2">Printer</TextTitle>
                        <Text variant="body2" gutterBottom>
                            Det er også muligt at benytte vores printer. Det
                            koster 25 øre per kopi S/H og 50 øre for
                            farvekopi/print uanset papirformat.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            P.t. foregår det med selvansvarlighed; du skriver
                            op, hvor mange kopier du tager og skriver en mail
                            til{' '}
                            <a href="mailto:kontakt@bulowsvej10.dk">
                                <LinkText>kontakt@bulowsvej10.dk</LinkText>
                            </a>{' '}
                            .
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Når antallet runder 1000 stk. og/eller ved
                            afslutning af medlemskabet/lejeperioden, sender vi
                            en faktura.
                        </Text>

                        <TextTitle variant="body2">Køkkenfaciliteter</TextTitle>
                        <Text variant="body2" gutterBottom>
                            Adgang til tekøkken med vandkoger, mikroovn og
                            køleskab. Medlemmer kan frit tage te og kaffe til
                            sig selv. Spisning må kun foregå i køkkenet ikke i
                            de lejede lokaler.
                            <br />
                            NB! Ingen drikkevarer på vores klaverer.
                            <br />
                            Husk, at vaske op efter dig selv.
                        </Text>

                        <TextTitle variant="body2">Røgfri matrikel</TextTitle>
                        <Text variant="body2" gutterBottom>
                            Hele matriklen er røgfri.
                        </Text>
                        <TextTitle variant="body2">
                            Skærmfri matrikel for børn under 18
                        </TextTitle>
                        <Text variant="body2" gutterBottom>
                            På alle inden og udendørs fællesarealer har vi en
                            regel om at børen under 18 år ikke benytter hverken
                            store eller små skærme. <br />
                            Når du har børn inde i dit lejede lokale bestemmer
                            du din egen skærmpolitik.
                        </Text>

                        <TextTitle variant="body2">Udendørs ophold</TextTitle>
                        <Text variant="body2" gutterBottom>
                            Udendørs ophold og aktiviteter må ikke være til gene
                            for naboerne.
                        </Text>
                        <br />
                        <br />
                        <Text>
                            Vi glæder os til at være vært for din aktivitet
                        </Text>
                        {/* <TextFromRichTextEditor userId={1} setTextLength={setTextLength} /> */}
                    </TermsOfPurchaseContainer>{' '}
                    <GradientOverlay />
                </>
            ) : (
                <>
                    <TextTitle variant="body2">Parkering</TextTitle>
                    <Text variant="body2" gutterBottom>
                        Biler kan parkeres i gården mod betaling{' '}
                        <LinkText
                            href="https://easypark.dk/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            (Easy Park)
                        </LinkText>
                        . Parker venligst, så der også er plads til andre.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Cykler skal parkeres foran huset (ikke bagved eller i
                        gården).
                    </Text>

                    <TextTitle variant="body2">Adgang</TextTitle>
                    <Text variant="body2" gutterBottom>
                        Adgang til huset sker via bagdøren.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Døren står permanent låst og kan kun åbnes via 'Åben
                        døren'-knappen på Min side når du er logget ind.
                        <br />
                        'Åben døren'-knappen aktiveres først, når du har er
                        blevet medlem og/eller lejet et lokale.
                    </Text>
                    {/* <Text variant="body2" gutterBottom>
              'Åben døren'-knappen aktiveres først, når du har tegnet abonnement.{" "}
            </Text>

            <Text variant="body2" gutterBottom>
              Indtil 13. oktober vil lokaleleje uden abonnement blive ledsaget
              af en anden adgangsmetode.{" "}
            </Text> */}

                    {/* <TextTitle variant="body2">Åben døren-knappen</TextTitle>
            <Text variant="body2" gutterBottom>
              Åben døren-knappen giver dig gyldig adgang til huset, når du har
              tegnet et abonnement.
            </Text> */}

                    <TextTitle variant="body2">Skofri bygning</TextTitle>
                    <Text variant="body2" gutterBottom>
                        Det meste af huset er skofrit område, skoene sættes i
                        garderoben i skoreolerne i lokalerne omkring
                        indgangsdøren.
                        <br />
                        Alternativt tages der et særligt overtræk ud over
                        skoene.
                        <br />
                        Hvis du har gæster, er det dit ansvar, at de også tager
                        skoene af.
                    </Text>

                    <TextTitle variant="body2">
                        Lokalernes stand og brug
                    </TextTitle>
                    <Text variant="body2" gutterBottom>
                        Lokalerne forlades altid i samme stand og
                        oprydningstilstand som ved ankomsten.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Vinduerne skal være lukkede, når lokalet forlades. Alle
                        aktiviteter der afgiver lyd, skal være for lukkede
                        vinduer. Der udlejes kun til relativt stille
                        aktiviteter, f.eks. brug af akustiske instrumenter og
                        sang eller anlæg på et behersket lydniveau.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Det er obligatorisk at udlufte lokalet efter brug.
                    </Text>

                    <TextTitle variant="body2">Affald</TextTitle>
                    <Text variant="body2" gutterBottom>
                        Affald tages med ned til vores affaldssortering i
                        køkkenet.
                    </Text>

                    <TextTitle variant="body2">Lokalernes inventar</TextTitle>
                    <Text variant="body2" gutterBottom>
                        Den Lille Musikskole, som er den største bruger af
                        lokalerne, har en del rekvisitter liggende, som ikke må
                        bruges af andre.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        NB! De farvede taburetter må KUN benyttes af børn.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Der er klaverer i alle rum. De kan bruges frit, husk at
                        lukke låget ned, når du går. Brug af andre instrumenter,
                        inventar og rekvisitter end klavererne, kræver særskilt
                        aftale med musikskolen.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Vi har derudover faldunderlagsmåtter, der dækker det
                        meste af arealet i det største rum. Vi har også 10
                        meditationspuder, massagebriks, samt tæpper, borde og
                        stole.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Det er også muligt at benytte vores printer. Det koster
                        25 øre per kopi S/H og 50 øre for farvekopi/print uanset
                        papirformat.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        P.t. foregår det med selvansvarlighed; du skriver op,
                        hvor mange kopier du tager og skriver en mail til{' '}
                        {/* <a> */}
                        <LinkText href="mailto:kontakt@bulowsvej10.dk">
                            kontakt@bulowsvej10.dk
                        </LinkText>
                        {/* </a> */} .
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Når antallet runder 1000 stk. og/eller ved afslutning af
                        medlemskabet/lejeperioden, sender vi en faktura.
                    </Text>

                    <TextTitle variant="body2">Køkkenfaciliteter</TextTitle>
                    <Text variant="body2" gutterBottom>
                        Adgang til tekøkken med vandkoger, mikroovn og køleskab.
                        Medlemmer kan frit tage te og kaffe til sig selv.
                        Spisning må kun foregå i køkkenet ikke i de lejede
                        lokaler.
                        <br />
                        NB! Ingen drikkevarer på vores klaverer.
                        <br />
                        Husk, at vaske op efter dig selv.
                    </Text>

                    <TextTitle variant="body2">Røgfri matrikel</TextTitle>
                    <Text variant="body2" gutterBottom>
                        Hele matriklen er røgfri.
                    </Text>
                    <TextTitle variant="body2">
                        Skærmfri matrikel for børn under 18
                    </TextTitle>
                    <Text variant="body2" gutterBottom>
                        På alle inden og udendørs fællesarealer har vi en regel
                        om at børen under 18 år ikke benytter hverken store
                        eller små skærme. <br />
                        Når du har børn inde i dit lejede lokale bestemmer du
                        din egen skærmpolitik.
                    </Text>

                    <TextTitle variant="body2">Udendørs ophold</TextTitle>
                    <Text variant="body2" gutterBottom>
                        Udendørs ophold og aktiviteter må ikke være til gene for
                        naboerne.
                    </Text>
                    <br />
                    <br />
                    <Text>
                        Vi glæder os til at være vært for din aktivitet.
                    </Text>
                </>
            )}
        </OuterContainer>
    );
}

export default HouseRules;
