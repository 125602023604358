import {
    CardActionArea,
    Box,
    Typography,
    CardMedia,
    CardContent
} from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import StaircaseImage from '../staircase-image/StaircaseImage';
import StaircaseImageFlipped from '../staircase-image/StaircaseImageFlipped';
import BlueStaircase from '../../assets/staircase/staircase-blue.png';
import PurpleStaircase from '../../assets/staircase/staircase-purple.png';
import GreenStaircase from '../../assets/staircase/staircase-green.png';
import OrangeStaircase from '../../assets/staircase/staircase-orange.png';
import YellowStaircase from '../../assets/staircase/staircase-yellow.png';
import WhiteStaircase from '../../assets/staircase/staircase-white.png';

const CustomCard = styled.div`
    border-radius: 0;
    border-bottom: 2px solid
        ${(props) => props.theme.palette.primary.contastText};
    margin-bottom: 1.5rem;
    color: ${(props) => props.theme.palette.primary.contrastText};

    // Give a colourful overlay on hover
    &:hover .room-image-overlay {
        display: block;
    }

    &:hover .custom-card-content {
        color: rgba(${(props) => props.color} 1);
    }

    &:hover {
        border-bottom: 2px solid rgba(${(props) => props.color} 1);
    }

    // Flip the staircase image on hover
    &:hover .room-card-staircase-image-flipped-container {
        display: none;
    }
    &:hover .room-card-staircase-image-container {
        display: block;
    }
`;

//eslint-disable-next-line
const CardMediaOverlay = styled.div`
    display: none;
    width: 100%;
    height: 15rem;
    position: absolute;
    top: 0;
    background-image: linear-gradient(
            rgba(${(props) => props.color} 0.4),
            rgba(${(props) => props.color} 0.4)
        ),
        url(${(props) => props.imageurl}) !important;
`;

const SeeMoreLink = styled(Typography)`
    text-decoration: underline;
    font-size: 0.9rem;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        font-size: 0.85rem;
    }
`;

const CustomCardMedia = styled(CardMedia)`
    height: 15rem;
`;

const StaircaseImageFlippedContainer = styled.div`
    margin-right: 1rem;
    & img {
        position: relative;
        top: 3px;
    }
`;

const StaircaseImageContainer = styled.div`
    margin-right: 1rem;
    display: none;
    & img {
        position: relative;
        top: 3px;
    }
`;

const RoomTitle = styled(Typography)`
    text-decoration: none !important;
    font-size: 0.9rem;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        font-size: 0.83rem;
    }
`;

function RoomCard({ title, imageUrl, to, color, colorname }) {
    const returnStaircaseIcon = (colorname) => {
        switch (colorname) {
            case 'blue':
                return BlueStaircase;
            case 'purple':
                return PurpleStaircase;
            case 'green':
                return GreenStaircase;
            case 'orange':
                return OrangeStaircase;
            case 'yellow':
                return YellowStaircase;
            case 'white':
                return WhiteStaircase;

            default:
                return WhiteStaircase;
        }
    };
    return (
        <>
            {title === 'Lokale 1' ||
            title === 'Lokale 3' ||
            title === 'Lokale 4' ? (
                <Link to={to} style={{ pointerEvents: 'none' }}>
                    <CustomCard overlaycolor={color} color={color}>
                        <CardActionArea disableRipple>
                            {/* Imageurl is lowercase on purpose, otherwise we get warning */}
                            <CustomCardMedia
                                imageurl={imageUrl}
                                title={title}
                                image={imageUrl}
                            />
                            {/* <CardMediaOverlay className="room-image-overlay" color={color} /> */}
                            <CardContent className="custom-card-content">
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <Box display="flex">
                                        <StaircaseImageFlippedContainer className="room-card-staircase-image-flipped-container">
                                            <StaircaseImageFlipped
                                                src={returnStaircaseIcon(
                                                    'white'
                                                )}
                                                alt="staircase-logo"
                                                width="1"
                                            />
                                        </StaircaseImageFlippedContainer>
                                        <StaircaseImageContainer className="room-card-staircase-image-container">
                                            {colorname ? (
                                                <StaircaseImage
                                                    src={returnStaircaseIcon(
                                                        colorname
                                                    )}
                                                    alt="staircase-logo"
                                                    width="1"
                                                />
                                            ) : (
                                                <StaircaseImage
                                                    src={returnStaircaseIcon(
                                                        'white'
                                                    )}
                                                    alt="staircase-logo"
                                                    width="1"
                                                />
                                            )}
                                        </StaircaseImageContainer>
                                        <RoomTitle variant="body2">
                                            {title}
                                        </RoomTitle>
                                    </Box>
                                    <SeeMoreLink variant="body2">
                                        Se mere
                                    </SeeMoreLink>
                                </Box>
                            </CardContent>
                        </CardActionArea>
                    </CustomCard>
                </Link>
            ) : (
                <Link to={to}>
                    <CustomCard overlaycolor={color} color={color}>
                        <CardActionArea disableRipple>
                            {/* Imageurl is lowercase on purpose, otherwise we get warning */}
                            <CustomCardMedia
                                imageurl={imageUrl}
                                title={title}
                                image={imageUrl}
                            />
                            {/* <CardMediaOverlay className="room-image-overlay" color={color} /> */}
                            <CardContent className="custom-card-content">
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <Box display="flex">
                                        <StaircaseImageFlippedContainer className="room-card-staircase-image-flipped-container">
                                            <StaircaseImageFlipped
                                                src={returnStaircaseIcon(
                                                    'white'
                                                )}
                                                alt="staircase-logo"
                                                width="1"
                                            />
                                        </StaircaseImageFlippedContainer>
                                        <StaircaseImageContainer className="room-card-staircase-image-container">
                                            {colorname ? (
                                                <StaircaseImage
                                                    src={returnStaircaseIcon(
                                                        colorname
                                                    )}
                                                    alt="staircase-logo"
                                                    width="1"
                                                />
                                            ) : (
                                                <StaircaseImage
                                                    src={returnStaircaseIcon(
                                                        'white'
                                                    )}
                                                    alt="staircase-logo"
                                                    width="1"
                                                />
                                            )}
                                        </StaircaseImageContainer>
                                        <RoomTitle variant="body2">
                                            {title}
                                        </RoomTitle>
                                    </Box>
                                    <SeeMoreLink variant="body2">
                                        Se mere
                                    </SeeMoreLink>
                                </Box>
                            </CardContent>
                        </CardActionArea>
                    </CustomCard>
                </Link>
            )}
        </>
    );
}

export default RoomCard;
