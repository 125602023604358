import { ClickAwayListener, Popper } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

import QuestionmarkIcon from '../../assets/icons/questionmark.png';

const QuestionmarkComponent = styled.img.attrs((props) => ({
    className: props.className
}))`
    width: ${(props) => props.width};
    margin-left: ${(props) => (props.marginleft ? props.marginleft : '0.5rem')};
    margin-top: ${(props) => (props.margintop ? props.margintop : '0.5rem')};
    cursor: pointer;
`;

const QuestionmarkContainer = styled.div``;

const PopperText = styled.div`
    background-color: ${(props) => props.theme.palette.primary.light};
    color: ${(props) => props.theme.palette.primary.contrastText};
    padding: 0.5rem;
    position: relative;
    z-index: 99;
    background: yellow;
`;

function Questionmark({ width, explainerText, marginLeft, marginTop }) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    return (
        <React.Fragment>
            <QuestionmarkContainer>
                <ClickAwayListener onClickAway={handleClickAway}>
                    <QuestionmarkComponent
                        src={QuestionmarkIcon}
                        alt="questionmark"
                        width={width}
                        onClick={handleClick}
                        aria-describedby={id}
                        className="questionmark-component"
                        marginleft={marginLeft}
                        margintop={marginTop}
                    />
                </ClickAwayListener>
            </QuestionmarkContainer>
            <Popper id={id} open={open} anchorEl={anchorEl}>
                <PopperText className="popper">{explainerText}</PopperText>
            </Popper>
        </React.Fragment>
    );
}

export default Questionmark;
