import React, { useCallback, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import RegistrationContentContainer from '../../../components/containers/registration/RegistrationContentContainer';
import {
    getCurrentStage,
    getOrderInfo,
    // getPaymentParamsChecksum,
    returnAuthenticated
} from '../../../context/getData';
import RegularHeadline from '../../../components/headlines/RegularHeadline';
import { Box, Typography } from '@material-ui/core';
import CheckboxComponent from '../../../components/checkbox/CheckboxComponent';
import styled from 'styled-components';
import ProgressBar from '../../../components/progress-bar/ProgressBar';
import HouseRulesDialog from '../../../components/dialogs/house-rules-dialog/HouseRulesDialog';
import TermsDialog from '../../../components/dialogs/terms-dialog/TermsDialog';
import ResendCodeDialog from '../../../components/dialogs/resend-code-dialog/ResendCodeDialog';
import ErrorDialog from '../../../components/dialogs/error-dialog/ErrorDialog';
import {
    fetchWithTimeout,
    getAndReturnResponse
} from '../../../context/helperFunctions';
import Loader from '../../../components/loader/Loader';
import RegistrationButton from '../../../components/buttons/registration-button/RegistrationButton';
import ReturnLinkReusable from '../../../components/links/ReturnLinkReusable';
import { getLocalStorageJson, getUser } from '../../../util';
import ContinueToPayDialog from '../../../components/dialogs/approve-before-quickpay/ContinueToPayDialog';
import { withRouter } from 'react-router-dom';
import Dankort from '../../../assets/logos/DK_Logo_CMYK.png';
import Mastercard from '../../../assets/logos/mastercard.svg';
import Visa from '../../../assets/logos/visa.svg';
import HeadersContainer from '../../../components/headers/headers-container/HeadersContainer';
import { useEffect } from 'react';
// import MobilePay from '../../../assets/logos/mobilepay.png';
import InactiveButton from '../../../components/buttons/inactive-button/InactiveButton';

const CheckboxText = styled(Typography)`
    margin-top: 6px;

    color: ${(props) =>
        props.error === 'true'
            ? props.theme.palette.error.main
            : props.theme.palette.primary.contrastText};
`;

const UnderlinedText = styled.span`
    text-decoration: underline;
    cursor: pointer;
`;

const HiddenFormContainer = styled.div`
    display: none;

    & form {
        display: none;
    }
`;

const NotesContainer = styled(Box)`
    width: 60%;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 100%;
    }
`;

// const DankortImage = styled.img`
//   width: 3.5rem;
//   height: fit-content;
//   margin-right: 1rem;
// `;

const DescriptionText = styled(Typography)``;

const CardsLogoContainer = styled.div`
    display: flex;
    width: fit-content;
    justify-content: space-between;
    flex-direction: row;
`;
const Logo = styled.img`
    margin-top: 20px;
    margin-right: 10px;
    width: 38px;
    height: 25px;
`;

// const DescriptionTextBold = styled(Typography)`
//   font-family: "Benton Sans Med";
//   margin-right: 0.3rem;

//   &.bigger-margin {
//     margin-bottom: 1rem;
//   }
// `;

function AcceptTermsPage(props) {
    const user = getUser();
    const userId = user.userId;
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const [checkboxErrorTerms, setCheckboxErrorTerms] = useState('false');

    const [acceptedShoes, setAcceptedShoes] = useState(false);
    const [checkboxErrorShoes, setCheckboxErrorShoes] = useState('false');

    const [acceptedParking, setAcceptedParking] = useState(false);
    const [checkboxErrorParking, setCheckboxErrorParking] = useState('false');
    // const [acceptedSubscriptionCon, setAcceptedSubscriptionCon] = useState(false);
    // const [checkboxErrorSubscriptionCon, setCheckboxErrorSubscriptionCon] = useState("false");

    const [houseRulesDialog, setHouseRulesDialog] = useState(false);
    const [termsDialog, setTermsDialog] = useState(false);
    const [error, setError] = useState(false);
    const [updateSystemError, setUpdateSystemError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    // eslint-disable-next-line
    const [isPaymentOrderType, setIsPaymentOrderType] = useState(true);

    const [continueToPay, setContinueToPay] = useState(false);

    const [verificationMissingDialog, setVerificationMissingDialog] = useState(
        false
    );
    const [acceptedAllConditions, setAcceptedAllConditions] = useState(false);
    // const [rates, setRates] = useState([]);

    // There are 4 different order types:
    // - none (nothing is added to cart)
    // - subscription
    // - event
    // - combo (subscription + event)

    //   const context = useContext(Context);

    const formRef = useRef();
    //   const termsRef = useRef();

    const handleCheckboxChangeShoes = () => {
        if (checkboxErrorShoes) {
            setCheckboxErrorShoes('false');
        }
        setAcceptedShoes(!acceptedShoes);
    };

    const handleCheckboxChangeParking = () => {
        if (checkboxErrorParking) {
            setCheckboxErrorParking('false');
        }
        setAcceptedParking(!acceptedParking);
    };

    // const handleSubscriptionConditions = () => {
    //   if (checkboxErrorSubscriptionCon) {
    //     setCheckboxErrorSubscriptionCon("false");
    //   }
    //   setAcceptedSubscriptionCon(!acceptedSubscriptionCon);
    // };

    const handleCheckBoxChangeTerms = () => {
        if (checkboxErrorTerms) {
            setCheckboxErrorTerms('false');
        }
        setAcceptedTerms(!acceptedTerms);
    };
    const backToCalendar = () => {
        if (returnAuthenticated) props.history.push('/booking-kalender');
    };

    const postOrder = async (amount, currency, productsBody) => {
        const requestURL = `/api/v1/user/${userId}/order/event`;
        const requestBody = {
            userId,
            amount,
            currency,
            orders: productsBody
        };
        try {
            const response = await fetchWithTimeout(requestURL, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                body: JSON.stringify(requestBody),
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${user.token}`,
                    'Content-Type': 'application/json'
                },
                timeout: 10000
            });
            if (response.status === 200) {
                const json = await response.json();
                return json;
            } else {
                // If we have an error, due to that the user is not verified, we need to show an error message about that.
                const data = await response.json();
                if (data && data.error) {
                    return { error: data.error };
                }
            }
        } catch (e) {
            return { error: e.message };
        }
    };

    const getPaymentParamsChecksum = async (
        orderId,
        type,
        amount,
        currency,
        description,
        productsBody
    ) => {
        const user = getUser();
        if (!user) {
            return { error: 'User not logged in' };
        }
        let params;
        let requestURL = '/api/v1/payment/params-checksum';
        let requestBody = {
            userId,
            orderId,
            type,
            amount,
            currency,
            description,
            show3DSecure: 1,
            orders: productsBody
        };
        try {
            const response = await fetchWithTimeout(requestURL, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                body: JSON.stringify(requestBody),
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${user.token}`,
                    'Content-Type': 'application/json'
                },
                timeout: 10000
            });
            if (response.status === 200) {
                params = await response.json();
                // console.log(
                //     'Payment params checksum:',
                //     JSON.stringify(params, null, 2)
                // );
            } else {
                // If we have an error, due to that the user is not verified, we need to show an error message about that.
                const data = await response.json();
                if (data && data.message) {
                    return { error: data.message };
                }
            }
        } catch (err) {
            // Timeouts if the request takes more than 10 seconds
            console.log(err);
            // const aborted = (err.name === 'AbortError');
        }
        return params;
    };

    const handleError = () => {
        setError(true);
        setErrorMessage([
            'Noget gik galt.',
            <br />,
            'Du kan enten prøve igen eller kontakte os.',
            <br />,
            'Vi beklager.'
        ]);
    };

    const handlePaymentForm = () => {
        console.log('handling payment form');
        const orderInfo = getOrderInfo();
        // return console.log('show orderinfo', orderInfo);
        // return console.log('handling payment form', orderInfo.orderType);
        if (!orderInfo) {
            return handleError();
        }
        // anonymous self-executing function must be followed by a semicolon or
        // next statement is compiled as an argument to the result of the first
        (async () => {
            // Create order and retrieve order ID for the subsequent API call
            const orderResponse = await postOrder(orderInfo.orderTotal, 'DKK', {
                products: orderInfo.products,
                rates: orderInfo.rates ? orderInfo.rates : []
            });
            // console.log(
            //     'postOrder response',
            //     JSON.stringify(orderResponse, null, 2)
            // );
            if (!orderResponse || orderResponse.error) {
                console.log('got here what', orderResponse);
                // 'User is not verified.'
                if (orderResponse && orderResponse.error === 1024) {
                    return setVerificationMissingDialog(true);
                } // 'User is already subscribed.'
                else if (orderResponse && orderResponse.error === 1025) {
                    setError(true);
                    setErrorMessage('Du har allerede oprettet et medlemskab.');
                } else {
                    setError(true);
                    setErrorMessage(
                        'Noget gik galt i systemet. Prøv igen, eller kontakt os på kontakt@bulowsvej10.dk.'
                    );
                }

                setIsLoading(false);
                return;
            }

            let response = await getPaymentParamsChecksum(
                orderResponse.orderID,
                orderInfo.orderType,
                // for now this is hardcoded, since we only have one product, which can be bought only one at a time.
                orderInfo.orderTotal,
                'DKK',
                orderInfo.orderName,
                {
                    products: orderInfo.products,
                    rates: orderInfo.rates ? orderInfo.rates : []
                },
                userId
            );

            // console.log('show resp', response);

            if (!response || !response.params || response.error) {
                console.log('should display error', response.error);
                if (response.error === 'User is not verified.') {
                    setVerificationMissingDialog(true);
                } else if (response.error === 'User is already subscribed.') {
                    setError(true);
                    setErrorMessage('Du har allerede oprettet et medlemskab.');
                } else if (response.error === 'User not logged in') {
                    setError(true);
                    setErrorMessage('Vær venlig at logge ind');
                } else {
                    setError(true);
                    setErrorMessage('Noget gik galt. Prøv igen.');
                }

                setIsLoading(false);
            } else {
                // console.log("Submit form to QuickPay payment window");
                // console.log(JSON.stringify(response.params, null, 2));

                let form = document.getElementById('quickpayform');
                for (let key in response.params) {
                    let input = document.createElement('INPUT');
                    input.setAttribute('type', 'hidden');
                    input.setAttribute('name', key);
                    input.setAttribute('value', response.params[key]);
                    form.appendChild(input);
                }
                form.submit();
            }
        })();
    };
    const getSystemUpdateText = useCallback(async () => {
        let res = await getAndReturnResponse('/api/v1/system-update');
        let body = await res.text();
        let key = document.location.hostname + ':system-update';
        if (res.status === 200 && body) {
            if (typeof Storage !== 'undefined')
                localStorage.setItem(key, 'true');
            return body;
        }
        if (typeof Storage !== 'undefined') localStorage.setItem(key, 'false');
        return null;
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (acceptedTerms && acceptedShoes && acceptedParking) {
            setAcceptedAllConditions(true);
        } else {
            setAcceptedAllConditions(false);
        }
    }, [acceptedTerms, acceptedShoes, acceptedParking]);

    const onSubmit = async () => {
        setContinueToPay(false);
        // TODO check valid session from utils
        async function fetchData() {
            let systemUpdateText = await getSystemUpdateText();
            return systemUpdateText;
        }
        const systemUpdateText = await fetchData();
        const systemUsers = [
            'admin@denlillemusikskole.dk',
            'test@denlillemusikskole.dk',
            'lk@denlillemusikskole.dk',
            'rj@denlillemusikskole.dk',
            'nlh@denlillemusikskole.dk'
        ];
        const key = 'bulowsvej10.dk:state-history';
        const dataFromLocalStorage = getLocalStorageJson(key, {});
        let userEmail;
        if (
            dataFromLocalStorage &&
            dataFromLocalStorage.currentUser &&
            dataFromLocalStorage.currentUser.userEmail
        ) {
            userEmail = dataFromLocalStorage.currentUser.userEmail;
        }

        if (systemUpdateText != null && !systemUsers.includes(userEmail)) {
            setIsLoading(false);
            setErrorMessage(
                'Siden er skrivebeskyttet under systemopdateringer. Kom venligst tilbage senere.'
            );
            return setUpdateSystemError(true);
        }

        // Call QP endpoint
        handlePaymentForm();
    };

    // ComponentDidMount
    useEffect(() => {
        const order = getOrderInfo();
        if (order && order.orderType && order.orderType !== 'payment') {
            setIsPaymentOrderType(false);
        }
    }, []);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.7 }}
        >
            <HeadersContainer />

            {/* <span ref={contentRef}></span> */}
            <RegistrationContentContainer
                marginTop="2.5rem"
                mobilePaddingTop="1rem"
            >
                {returnAuthenticated() ? (
                    <ProgressBar
                        currentStage={'Betaling'}
                        type={getCurrentStage()}
                    />
                ) : (
                    <ProgressBar currentStage={'Betaling'} />
                )}

                <ReturnLinkReusable destination="kurven" url="/opsummering" />

                <Box pt="1rem">
                    <RegularHeadline title="Accepter betingelser og godkend betaling" />
                </Box>
                {/* <TermsOfPurchase /> */}

                <Box display="flex" position="relative">
                    <Box width="fit-content">
                        <CheckboxComponent
                            onCheckboxChange={handleCheckBoxChangeTerms}
                            position="static"
                            error={checkboxErrorTerms}
                            linksInText="true"
                        />
                    </Box>

                    <CheckboxText variant="body2" error={checkboxErrorTerms}>
                        Jeg har læst og accepteret{' '}
                        <UnderlinedText onClick={() => setTermsDialog(true)}>
                            Handels- og medlemsbetingelser{' '}
                        </UnderlinedText>{' '}
                        samt{' '}
                        <UnderlinedText
                            onClick={() => setHouseRulesDialog(true)}
                        >
                            husordenen
                        </UnderlinedText>
                        .*
                    </CheckboxText>
                </Box>

                <Box display="flex" position="relative">
                    <Box width="fit-content">
                        <CheckboxComponent
                            onCheckboxChange={() => handleCheckboxChangeShoes()}
                            position="static"
                            error={checkboxErrorShoes}
                            linksInText="true"
                        />
                    </Box>

                    <CheckboxText variant="body2" error={checkboxErrorShoes}>
                        Udendørssko skal tages af og stilles i garderoben.
                        <br />
                        Som lejer/medlem har du ansvar for, at dine gæster
                        overholder dette.
                    </CheckboxText>
                </Box>

                <Box display="flex" position="relative">
                    <Box width="fit-content">
                        <CheckboxComponent
                            onCheckboxChange={() =>
                                handleCheckboxChangeParking()
                            }
                            position="static"
                            error={checkboxErrorShoes}
                            linksInText="true"
                        />
                    </Box>

                    <CheckboxText variant="body2" error={checkboxErrorParking}>
                        Undgå P-bøder! Indstil din parkeringsapp, ved ankomst,
                        til område 118. <br />
                        Regler og vilkår fremgår af skiltene på pladsen.
                    </CheckboxText>
                </Box>

                {acceptedAllConditions ? (
                    <>
                        {isLoading ? (
                            <Box mt="1rem">
                                <Loader />
                            </Box>
                        ) : (
                            <RegistrationButton
                                title="Godkend"
                                onButtonClick={() => setContinueToPay(true)}
                                mobileMargin="1.5rem 0"
                            />
                        )}
                    </>
                ) : (
                    <>
                        <InactiveButton
                            title="Godkend"
                            mobileMargin="1.5rem 0"
                        />
                    </>
                )}

                {/* Hidden form is needed for QuickPay payment */}
                <HiddenFormContainer ref={formRef}></HiddenFormContainer>

                <NotesContainer display="block" alignItems="center" mt="3rem">
                    {/* <DankortImage src={DankortIcon} alt="dankort" /> */}
                    {/*     Removed this, because mobilepay is not working now
                    {isPaymentOrderType ? (
                        <DescriptionText variant="body2">
                            Betaling med: Dankort, VISA/Dankort, Mastercard og
                            MobilePay. <br />
                            <br /> Alle priser er i danske kroner (DKK).
                        </DescriptionText>
                    ) : ( */}

                    <DescriptionText variant="body2">
                        Betaling med: Dankort, VISA/Dankort og Mastercard.{' '}
                        <br />
                        <br /> Alle priser er i danske kroner (DKK).
                    </DescriptionText>
                    {/* )} */}

                    <CardsLogoContainer>
                        <Logo src={Dankort} alt="logo"></Logo>
                        <Logo
                            src={Visa}
                            alt="Visa logo"
                            style={{ backgroundColor: 'white' }}
                        ></Logo>
                        <Logo
                            src={Mastercard}
                            alt="Mastercard logo"
                            style={{
                                width: '42px',
                                height: '31px',
                                marginTop: '17px'
                            }}
                        ></Logo>
                        {/* Removed this, because mobilepay is not working now */}
                        {/* {isPaymentOrderType && (
                            <Logo
                                src={MobilePay}
                                alt="MobilePay subscriptions logo"
                                style={{
                                    width: '155px',
                                    height: '68px',
                                    marginTop: '-5px',
                                    marginLeft: '-35px'
                                }}
                            ></Logo>
                        )} */}
                    </CardsLogoContainer>
                </NotesContainer>

                <NotesContainer mt="1rem">
                    <DescriptionText variant="body2">
                        Ved tekniske problemer kontakt os på{' '}
                        <a href="mailto:kontakt@bulowsvej10.dk">
                            <UnderlinedText>mail</UnderlinedText>
                        </a>
                        .
                    </DescriptionText>
                </NotesContainer>
            </RegistrationContentContainer>

            {houseRulesDialog && (
                <HouseRulesDialog close={() => setHouseRulesDialog(false)} />
            )}

            {termsDialog && <TermsDialog close={() => setTermsDialog(false)} />}

            {verificationMissingDialog && (
                <ResendCodeDialog
                    close={() => setVerificationMissingDialog(false)}
                    isWarning={true}
                />
            )}

            {continueToPay && (
                <ContinueToPayDialog
                    close={() => setContinueToPay(false)}
                    onSubmit={onSubmit}
                    backToCalendar={backToCalendar}
                />
            )}

            {error && (
                <ErrorDialog
                    dialogTitle=""
                    errorMessage={errorMessage}
                    close={() => {
                        setErrorMessage('');
                        setError(false);
                    }}
                />
            )}
            {updateSystemError && (
                <ErrorDialog
                    dialogTitle=""
                    errorMessage={errorMessage}
                    close={() => {
                        setErrorMessage('');
                        setUpdateSystemError(false);
                        window.location.reload();
                    }}
                />
            )}

            <form
                id="quickpayform"
                action="https://payment.quickpay.net"
                method="POST"
            ></form>
        </motion.div>
    );
}

export default withRouter(AcceptTermsPage);
